import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import qs from "qs";
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Row, Col } from 'antd';
import { FiDownload, FiPrinter } from "react-icons/fi";
import { isArray, isEmpty } from "lodash";
import useApi from '../../hooks/useApi';
import MusicSelectionsPDF from '../../pdfs/musicSelectionsPDF';
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import { getAllEventSpecialEvents, getMusicSelectionDetails } from '../../api';

const MusicPreviewPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [eventGenres, setEventGenres] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0)
    const eventId = params.id;
    if (!eventId) {
      navigate("/", { replace: true })
    } else {
      loadPage()
    }
  }, [])

  const loadPage = async () => {
    try {
      const response = await sendRequest(getAllEventSpecialEvents(params.id));
      const sectionResults = isArray(response.special_event_sections) ? response.special_event_sections : []
      const genreResults = isArray(response.genres) ? response.genres : []
      const playlistResults = isArray(response.playlists) ? response.playlists : []

      const filteredSpecialEvents = sectionResults.map((x,i) => {
        const specialEvents = isArray(x.special_events) ? x.special_events : []
        return {
          ...x,
          special_events: specialEvents.filter(x => x.active)
        }
      })
      setSpecialEventSections(filteredSpecialEvents)
      setEventGenres(genreResults)
      setPlaylists(playlistResults)
      setEventName(response.event_name)
      setEventDate(moment(response.event_date))
      setLoading(false)

      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (queryStrings.action == "print") {
        window.print()
      }
    } catch {}
  }

  const downloadPDF = async () => {
    try {
      const eventDetails = await getMusicSelectionDetails(params.id)
      const blob = await pdf(( renderPDF(eventDetails.data) )).toBlob();
      saveAs(blob, `music-selections-${eventDate.format("YYYY-MM-DD")}.pdf`)
    } catch { }
  }

  const renderPDF = (eventDetails) => {
    return (
      <Document>
        <MusicSelectionsPDF event={eventDetails}/>
      </Document>
    )
  }

  const renderSong = (song, index) => {
    return (
      <div key={index} className="mb-5">
        <div className="fw-500">{ song.title } - <span className="fw-400 c-text-gray">{ song.artist }</span></div>
        { !isEmpty(song.notes) && (
          <div className="fs-12 fw-500 bg-gray p-5 radius-4">{ song.notes }</div>
        )}
      </div>
    )
  }

  const renderSpecialEvent = (event, index) => {
    const eventSongs = isArray(event.songs) ? event.songs : []
    return (
      <div key={index}>
        <div className="fw-700 b-border pv-10 mh-15">
          <Row>
            <Col xs={24} sm={12}>
              { event.special_event_name }
            </Col>
            <Col xs={24} sm={12}>
              { eventSongs.map((x,i) => renderSong(x,i))}
              { eventSongs.length == 0 && (
                <div className="fw-500">--</div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderSection = (section, index) => {
    const specialEventsArray = isArray(section.special_events) ? section.special_events : []
    if (specialEventsArray.length == 0) {
      return null;
    }
    return (
      <div key={index}>
        <div className="">
          <div className="fs-16 fw-700 mt-15 ml-10 pv-5 pl-10 bg-gray" style={{ borderBottom: "1px solid #e8e8e8"}}>{ section.section_name }</div>
          <div className="mh-15">
            { specialEventsArray.map((x,i) => renderSpecialEvent(x,i))}
          </div>
        </div>
      </div>
    )
  }

  const renderGenre = (genre, index) => {
    return (
      <div className="mh-15 mv-5" key={index}>
        <div className="fw-600">{ genre.genre_name }</div>
        { !isEmpty(genre.notes) && (
          <div className="fs-12 fw-500 bg-gray p-5 radius-4">{ genre.notes }</div>
        )}
      </div>
    )
  }

  const renderPlaylist = (playlist, index) => {
    return (
      <div key={index}>
        <div className="fs-16 fw-700 mt-15 ml-10 pv-5 pl-10 bg-gray" style={{ borderBottom: "1px solid #e8e8e8"}}>{ playlist.playlist_name }</div>
        { isArray(playlist.songs) && playlist.songs.length > 0 ? (
          <div className="ml-30 mt-10">
            { playlist.songs.map((x,i) => renderSong(x,i)) }
          </div>
        ) : (
          <div className="mh-15 pl-10 mt-10 c-text-gray">
            No songs selected
          </div>
        )}
      </div>
    )
  }

  const renderGenres = () => {
    if (eventGenres.length > 0) {
      return eventGenres.map((x,i) => renderGenre(x,i))
    } else {
      return (
        <div className="mh-15 c-text-gray">
          No genres selected
        </div>
      )
    }
  }

  const renderContent = () => {
    return (
      <div>
        <div className="toolbar-container hide-print">
          <div className="toolbar-button" onClick={downloadPDF}>
            <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
          </div>
          <div className="toolbar-button" onClick={() => window.print()}>
              <FiPrinter style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Print
            </div>
        </div>
        <FloatingContainer verticalPadding={30} maxWidth={1000}>
          <div className="print-card">
            <div className="text-center fs-24 fw-700">{ eventName }</div>
            <div className="text-center fs-16 c-text-gray">{eventDate.format("dddd, MMMM D, YYYY")}</div>
            <div className="text-center fs-16 fw-700 mt-10">Music Selections</div>
            <div className="fs-18 fw-700 mt-30 mb-10 pb-5 pl-5" style={{ borderBottom: "3px solid #e8e8e8"}}>Special Events</div>
            <div className="">
              { specialEventSections.map((x,i) => renderSection(x,i))}
            </div>
            <div className="fs-18 fw-700 mt-30 mb-10 pb-5 pl-5" style={{ borderBottom: "3px solid #e8e8e8"}}>Favorite Genres</div>
            { renderGenres() }
            <div className="fs-18 fw-700 mt-30 mb-10 pb-5 pl-5" style={{ borderBottom: "3px solid #e8e8e8"}}>Playlists</div>
            { playlists.map((x,i) => renderPlaylist(x,i)) }
          </div>
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="bg-gray" style={{ minHeight: '100vh'}}>
      { renderContent() }
    </div>
  );
}

export default MusicPreviewPage;
