import React, { useEffect, useState } from "react";
import { Row, Col, Image, Modal, Form, InputNumber, Input, Select, Checkbox, Radio, DatePicker, Space } from 'antd';
import moment from 'moment';
import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";
import { isNull, isEmpty, get, isArray, cloneDeep, isUndefined } from 'lodash';
import FloatingContainer from '../../../components/floatingContainer'
import LoadingSpinner from '../../../components/loading';
import { formatCurrencyNotFixed } from '../../../helpers/contractHelper';
import { getDatePickerFormat } from '../../../helpers/dateHelper';
import { getPricingGuidePreview, createPricingGuideRequest } from '../../../api';
import { FiCheck } from "react-icons/fi";
import { FaRegSquare, FaCheckSquare } from "react-icons/fa";
import { LoadingOutlined } from '@ant-design/icons';
import { 
  renderInputField,
  renderDateField,
  renderEmailField,
  renderTextAreaField,
  renderFormLabel,
  renderSearchSelectField,
  renderTimeField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField,
  renderPhoneField
} from '../../../components/formFields'
import { MdOutlineClose } from "react-icons/md";
import { getCountryOptions, getPhoneNumber } from '../../../helpers/addressHelper';

const PricingGuidePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isFound, setFound] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [pricingGuide, setPricingGuide] = useState({});
  const [packages, setPackages] = useState([]);
  const [addOnCategories, setAddOnCategories] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [systemFields, setSystemFields] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isQuantityErrorModalVisible, setQuantityErrorModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [addressCountryCode, setAddressCountryCode] = useState("US");
  const [phoneCountryCode, setPhoneCountryCode] = useState("US");
  const [countries, setCountries] = useState([]);
  const [taxLabel, setTaxLabel] = useState("Tax");

  const params = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
  }, [])

  const fetchData = async () => {
    if (params.id) {
      try {
        setLoading(true)

        const response = await getPricingGuidePreview(params.id)
        const status = get(response, "data.status", "found")
        if (status == "not-found") {
          setFound(false)
        } else {
          setPricingGuide(response.data)
          setPackages(isArray(response.data.packages) ? response.data.packages : [])
          setAddOnCategories(isArray(response.data.add_ons) ? response.data.add_ons : [])
          setCustomFields(isArray(response.data.custom_fields) ? response.data.custom_fields : [])
          setSystemFields(isArray(response.data.system_fields) ? response.data.system_fields : [])
          setTaxLabel(isUndefined(response.data.account_settings.tax_label) ? "Tax" : response.data.account_settings.tax_label)

          var code = "US"
          if (!isEmpty(response.data.currency)) {
            if (response.data.currency == "GBP") {
              code = "GB"
            } else if (response.data.currency == "CAD") {
              code = "CA"
            } else {
              code = "US"
            }
          }
          setAddressCountryCode(code)
          setPhoneCountryCode(code)
          form.setFieldsValue({ country: code })

          if (isArray(response.data.event_types)) {
            const eventTypeData = response.data.event_types.map((eventType) => {
              return {
                value: eventType.event_type_id,
                text: eventType.event_type_name
              }
            })
            setEventTypes(eventTypeData);
          }

          const data = getCountryOptions()
          setCountries(data);
        }
        setLoading(false)
      } catch {
        setLoading(false)
      }
    } else {
      setFound(false)
    }
  }

  const onSubmit = async (values) => {
    var isQuantityError = false
    for (const addOn of selectedAddOns) {
      var addOnCategoriesClone = cloneDeep(addOnCategories)
      var allAddOns = addOnCategoriesClone.reduce((x,y) => {
        return x.concat(y.add_ons)
      }, [])
      const foundAddOn = allAddOns.find(x => x.add_on_id == addOn.add_on_id)
      if (isNull(addOn.quantity) || addOn.quantity == 0) {
        // Throw error if no quantity is selected
        isQuantityError = true
      } else if (!isUndefined(foundAddOn) && !isNull(foundAddOn.max_quantity) && addOn.quantity > foundAddOn.max_quantity) {
        // Throw error if more than max quantity is selected
        isQuantityError = true
      }
    }

    if (isQuantityError) {
      setQuantityErrorModalVisible(true)
      return
    }

    var eventTypeName = null;
    if (!isUndefined(values["account_event_type_id"])) {
      const eventType = eventTypes.find(x => x.value == values["account_event_type_id"])
      eventTypeName = !isEmpty(eventType) ? eventType.text : null
    }
    
    try {
      setSaving(true)

      const body = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        message: values.message,
        event_date: formatDateForDB(values.event_date),
        selected_services: {
          packages: selectedPackages,
          add_ons: selectedAddOns
        },
        custom_fields: customFields,
        account_event_type_id: !isUndefined(values["account_event_type_id"]) ? values["account_event_type_id"] : null,
        event_type_name: eventTypeName,
        phone: getPhoneNumber(values['phone'], phoneCountryCode),
        country: !isUndefined(values["country"]) ? values["country"] : null,
        address_line_1: !isUndefined(values["address_line_1"]) ? values["address_line_1"] : null,
        address_line_2: !isUndefined(values["address_line_2"]) ? values["address_line_2"] : null,
        city: !isUndefined(values["city"]) ? values["city"] : null,
        state: !isUndefined(values["state"]) ? values["state"] : null,
        zip: !isUndefined(values["zip"]) ? values["zip"] : null,
        start_time: formatTimeForDB(values["start_time"]),
        end_time: formatTimeForDB(values["end_time"]),
        venue_name: !isUndefined(values["venue_name"]) ? values["venue_name"] : null
      }

      await createPricingGuideRequest(params.id, body)

      setSuccessModalVisible(true)
      form.resetFields()
      setSelectedPackages([])
      setSelectedAddOns([])
      setSaving(false)
    } catch {
      setSaving(false)
    }
  }

  const formatDateForDB = (date) => {
    return date.format("YYYY-MM-DD")
  }

  const formatTimeForDB = (date) => {
    if (!isEmpty(date)) {
      return date.format("H:mm")
    }
    return null;
  }

  const isPackageSelected = (package_id) => {
    return selectedPackages.includes(package_id)
  }

  const isAddOnSelected = (add_on_id) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    var foundAddOn = selectedAddOnsClone.find(x => x.add_on_id == add_on_id)
    return !isUndefined(foundAddOn)
  }

  const onPackageToggle = (package_id) => {
    var selectedPackagesClone = cloneDeep(selectedPackages)
    if (!selectedPackagesClone.includes(package_id)) {
      selectedPackagesClone.push(package_id)
    } else {
      selectedPackagesClone = selectedPackagesClone.filter(x => x != package_id)
    }
    setSelectedPackages(selectedPackagesClone)
  }

  const onAddOnToggle = (add_on_id) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    var foundAddOn = selectedAddOnsClone.find(x => x.add_on_id == add_on_id)

    if (isUndefined(foundAddOn)) {
      const newAddOn = { add_on_id: add_on_id, quantity: 1 }
      selectedAddOnsClone.push(newAddOn)
    } else {
      selectedAddOnsClone = selectedAddOnsClone.filter(x => x.add_on_id != add_on_id)
    }
    setSelectedAddOns(selectedAddOnsClone)
  }

  const getAddOnQuantity = (add_on_id) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    var foundAddOn = selectedAddOnsClone.find(x => x.add_on_id == add_on_id)
    return !isUndefined(foundAddOn) ? foundAddOn.quantity : 1;
  }

  const setAddOnQuantity = (add_on_id, quantity) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    for (var addOn of selectedAddOnsClone) {
      if (addOn.add_on_id == add_on_id) {
        addOn.quantity = quantity
      }
    }
    setSelectedAddOns(selectedAddOnsClone)
  }

  const onSuccessClose = () => {
    setSuccessModalVisible(false)
    document.body.scrollTop = 0;
  }

  const onFieldTextChanged = (value, index) => {
    const newCustomFields = cloneDeep(customFields);
    newCustomFields[index].answer = value;
    setCustomFields(newCustomFields)
  }

  const onFieldCheckboxChanged = (value, index) => {
    const newCustomFields = cloneDeep(customFields);
    const currentAnswer = newCustomFields[index].answer
    var answerArray = currentAnswer.split("::");
    answerArray = answerArray.filter(x => x != "");
    const answerFound = answerArray.filter(x => x == value).length > 0;
    if (answerFound) {
      answerArray = answerArray.filter(x => x != value)
    } else {
      answerArray.push(value)
    }
    const answerString = answerArray.join("::");
    newCustomFields[index].answer = answerString;
    setCustomFields(newCustomFields)
  }

  const getTextValue = (index) => {
    if (customFields && customFields.length > 0) {
      return customFields[index].answer
    }
    return null;
  }

  const onFieldDateChanged = (date, dateString, index) => {
    const newCustomFields = cloneDeep(customFields);
    var newAnswer = "";
    if (date) {
      newAnswer = moment(date).format("YYYY-MM-DD");
    }
    newCustomFields[index].answer = newAnswer;
    setCustomFields(newCustomFields)
  }

  const getDateValue = (index) => {
    if (customFields && customFields.length > 0) {
      const date = customFields[index].answer
      if (date != "" && moment(date, "YYYY-MM-DD", true).isValid()) {
        return moment(date, "YYYY-MM-DD");
      }
    }
    return null;
  }

  const getCheckboxValue = (value, index) => {
    if (customFields && customFields.length > 0) {
      const currentAnswer = customFields[index].answer
      var answerArray = currentAnswer.split("::");
      answerArray = answerArray.filter(x => x != "");
      return answerArray.filter(x => x == value).length > 0
    }
    return false;
  }
  
  const isSystemFieldEnabled = (type) => {
    return !isEmpty(systemFields.find(x => x.type == type));
  }

  const isSystemFieldRquired = (type) => {
    const option = systemFields.find(x => x.type == type);
    return !isEmpty(option) ? option.required : false;
  }

  const onAddressCountryChange = (value) => {
    setAddressCountryCode(value)
  }

  const renderFormAddOnCategoryItem = (item, index) => {
    const allowQty = !isNull(item.max_quantity)
    var price = formatCurrencyNotFixed(item.price, pricingGuide.currency) + (item.taxable ? ` + ${taxLabel}` : "")
    if (allowQty) {
      price += " / each"
    }
    return (
      <div className="flex-row mb-5" key={index}>
        <div className="flex-0 pl-10" onClick={() => onAddOnToggle(item.add_on_id)}>
          { isAddOnSelected(item.add_on_id) ? (
            <FaCheckSquare style={{ fontSize: 20, color: "#536DFE", marginRight: 10 }}/>
          ) : (
            <FaRegSquare style={{ fontSize: 20, color: "#777", marginRight: 10 }}/>
          ) }
        </div>
        <div className="flex-1">
          <span onClick={() => onAddOnToggle(item.add_on_id)}>{ item.add_on_name } <span className="c-text-gray">({price})</span></span>
          { isAddOnSelected(item.add_on_id) && allowQty && (
            <div className="mt-10 mb-10">
              <span className="bg-gray p-15 radius-4">
                <span className="mr-5">Quantity:</span>
                <InputNumber
                  precision={0}
                  value={getAddOnQuantity(item.add_on_id)}
                  onChange={(value) => setAddOnQuantity(item.add_on_id, value)}
                />
              </span>
              { getAddOnQuantity(item.add_on_id) > item.max_quantity && (
                <div className="fs-12 c-red mt-10">The max quantity allowed is {item.max_quantity}.</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderFormAddOnCategory = (category, index) => {
    return (
      <div key={index} className="mb-15">
        <div className="fw-700 ml-5 mb-10">{category.category_name}</div>
        { category.add_ons.map((x,i) => renderFormAddOnCategoryItem(x,i))}
      </div>
    )
  }

  const renderAddOnCategoryItem = (item, index) => {
    const allowQty = !isNull(item.max_quantity)
    return (
      <div className="shadow-card p-20 mb-15" key={index}>
        <Row gutter={[15,15]}>
          { !isEmpty(item.photo) && (
            <Col xs={24} sm={6} md={5}>
              <div className="">
                <Image src={item.photo} style={{ width: '100%', borderRadius: 4 }}/>
              </div>
            </Col>
          )}
          <Col xs={24} sm={18} md={19}>
            <div className="fs-20 fw-700 flex-1 small-center">{ item.add_on_name }</div>
            <div className="fs-14 c-text-gray small-center mt-5 line-breaks">{ item.description }</div>
            { allowQty ? (
              <div className="fs-20 fw-700 flex-0 mt-10 small-center">{formatCurrencyNotFixed(item.price, pricingGuide.currency)} {renderOptionalTaxLabel(item)} <span className="fs-16 fw-500 c-text-gray">/ each</span></div>
            ) : (
              <div className="fs-20 fw-700 flex-0 mt-10 small-center">{formatCurrencyNotFixed(item.price, pricingGuide.currency)} {renderOptionalTaxLabel(item)}</div>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderAddOnCategory = (category, index) => {
    return (
      <div key={index} className="mb-30">
        <div className="fs-24 fw-700 ml-5 mb-10 small-center">{category.category_name}</div>
        { category.add_ons.map((x,i) => renderAddOnCategoryItem(x,i))}
      </div>
    )
  }

  const renderIncludedItem = (item, index, count) => {
    const borderClass = index < count - 1 ? "b-border" : ""
    return (
      <div className={`${borderClass} pv-20 mh-20`} key={index}>
        <div className="display-flex flex-row">
          <div className="display-flex flex-0">
            <FiCheck style={{ fontSize: 20, color: "#536DFE", marginRight: 15, marginTop: 2 }}/>
          </div>
          <div className="display-flex flex-1">
            <div className="display-flex flex-col">
              <div className="fs-14 fw-700">{ item.name }</div>
              <div className="fs-12 c-text-gray line-breaks">{ item.description }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderOptionalTaxLabel = (item) => {
    if (item.taxable && pricingGuide.include_tax_label) {
      return <span className="c-text-gray fs-16 fw-500">(+ {taxLabel})</span>
    }
    return null
  }

  const renderPackages = () => {
    if (packages.length > 0) {
      return (
        <>
          <FloatingContainer className="bg-blue-gradient text-center" verticalPadding={60} maxWidth={1000}>
            <div className="fs-24 fw-700 line-1-2 c-white">Packages</div>
          </FloatingContainer>
          <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={800}>
            <div className="">
              { packages.map((x,i) => (
                <div className="shadow-card b-border p-30 mb-15" key={i}>
                  <Row gutter={[20,20]}>
                    { !isEmpty(x.photo) && (
                      <Col xs={24} sm={6} md={5}>
                        <div className="mt-5">
                          <Image src={x.photo} style={{ width: '100%', borderRadius: 4 }}/>
                        </div>
                      </Col>
                    )}
                    <Col xs={24} sm={18} md={19} className="small-center">
                      <div className="fs-20 fw-700 flex-1 small-center">{ x.package_name }</div>
                      <div className="fs-14 c-text-gray small-center mt-5 line-breaks">{ x.description }</div>
                      <div className="fs-20 fw-700 flex-0 mt-10 small-center">{ formatCurrencyNotFixed(x.price, pricingGuide.currency) } {renderOptionalTaxLabel(x)}</div>
                    </Col>
                  </Row>
                  { x.included_items.length > 0 && (
                    <div className="bg-gray radius-8 mt-20">
                      { x.included_items.map((item,index) => renderIncludedItem(item, index, x.included_items.length))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </FloatingContainer>
        </>
      )
    }
    return null
  }

  const renderAddOns = () => {
    if (addOnCategories.length > 0) {
      return (
        <>
          <FloatingContainer className="bg-blue-gradient text-center" verticalPadding={60} maxWidth={1000}>
            <div className="fs-24 fw-700 line-1-2 c-white">Add-Ons</div>
          </FloatingContainer>
          <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={800}>
            { addOnCategories.map((x, i) => renderAddOnCategory(x,i))}
          </FloatingContainer>
        </>
      )
    }
    return null
  }

  const renderFormPackages = () => {
    if (packages.length > 0) {
      return (
        <>
          <div className="fw-700 line-1-2 mt-15 mb-10 bg-gray p-10">Packages</div>
          { packages.map((x,i) => (
            <div className="flex-row mb-5" key={i}>
              <div className="flex-0 pl-10" onClick={() => onPackageToggle(x.package_id)}>
                { isPackageSelected(x.package_id) ? (
                  <FaCheckSquare style={{ fontSize: 20, color: "#536DFE", marginRight: 10 }}/>
                ) : (
                  <FaRegSquare style={{ fontSize: 20, color: "#777", marginRight: 10 }}/>
                ) }
              </div>
              <div className="flex-1">
                <span onClick={() => onPackageToggle(x.package_id)}>{ x.package_name } <span className="c-text-gray">({formatCurrencyNotFixed(x.price, pricingGuide.currency)}{x.taxable && pricingGuide.include_tax_label ? ` + ${taxLabel}` : ""})</span></span>
              </div>
            </div>
          ))}
        </>
      )
    }
    return null
  }

  const renderFormAddOns = () => {
    if (addOnCategories.length > 0) {
      return (
        <>
          <div className="fw-700 line-1-2 mt-15 mb-10 bg-gray p-10">Add-Ons</div>
          { addOnCategories.map((x, i) => renderFormAddOnCategory(x,i))}
        </>
      )
    }
    return null
  }

  const renderCustomInput = (field, index) => {
    if (field.type == "text") {
      return <Input size="large" value={getTextValue(index)} onChange={(e) => onFieldTextChanged(e.target.value, index)}/>;
    } else if (field.type == "textarea") {
      return <Input.TextArea rows={3} size="large" value={getTextValue(index)} onChange={(e) => onFieldTextChanged(e.target.value, index)}/>;
    } else if (field.type == "select") {
      return (
        <Select
          style={{ width: '100%'}}
          placeholder={"Select"}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
          value={getTextValue(index)} 
          onChange={(e) => onFieldTextChanged(e, index)}
        >
          {field.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (field.type == "checkbox") {
      return field.options.map((option, optionIndex) => (
        <div className="mt-5" key={optionIndex}>
          <Checkbox value={option} checked={getCheckboxValue(option, index)} onChange={(e) => onFieldCheckboxChanged(e.target.value, index)}>{option}</Checkbox>
        </div>
      ))
    } else if (field.type == "radio") {
      return (
        <Radio.Group value={getTextValue(index)} onChange={(e) => onFieldTextChanged(e.target.value, index)}>
          <Space direction="vertical">
            {field.options.map((option, index) => (
              <Radio key={index} value={option}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (field.type == "date") {
      return <DatePicker size="large" format={getDatePickerFormat(pricingGuide.account_settings)} value={getDateValue(index)} onChange={(date, dateString) => onFieldDateChanged(date, dateString, index)}/>
    }
    return null;    
  }

  const renderCustomField = (item, index) => {
    var rule = { required: item.required, message: `This field is required!`}
    if (item.type == "text" || item.type == "textarea") {
      rule = { required: item.required, message: `This field is required!`, validateTrigger: "onBlur" }
    } else if (item.type == "checkbox") {
      rule = { required: false, message: `This field is required!` }
    }
    return (
      <Col xs={24} key={index}>
        { renderFormLabel(item.label)}
        <Form.Item
          name={`custom-${index}`}
          rules={[rule]}
        >
          { renderCustomInput(item, index)}
        </Form.Item>
      </Col>
    );
  }

  const renderCustomFields = () => {
    if (isArray(customFields)) {
      return customFields.map((x, i) => renderCustomField(x, i))
    }
    return null;
  }

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-white ph-20 text-center" verticalPadding={60} maxWidth={800}>
          { pricingGuide.logo ? (
            <img className="mb-30" src={pricingGuide.logo} style={{ width: 200 }}/>
          ) : (
            <div className="fs-14 fw-700 mb-30">{ pricingGuide.company_name }</div>
          )}
          <div className="fs-32 fw-700 line-1-2">{ pricingGuide.display_name }</div>
          <span className="bg-blue mt-15" style={{ height: 5, width: 80, display: 'inline-block'}}></span>
        </FloatingContainer>
        { renderPackages() }
        { renderAddOns() }
        { pricingGuide.display_contact_form && (
          <>
            <FloatingContainer className="bg-blue-gradient text-center" verticalPadding={60} maxWidth={1000}>
              <div className="fs-30 fw-700 line-1-2 c-white">Next Steps</div>
            </FloatingContainer>
            <FloatingContainer className="bg-gray ph-20" verticalPadding={40} maxWidth={800}>
              <div className="shadow-card b-border p-30 mb-15">
                <div className="fs-14 fw-700 text-center mb-5">Interested in booking?</div>
                <div className="fs-14 fw-500 text-center">
                  Fill out the form below to get started. Once we receive your information, we'll reach out to you with next steps.
                </div>
              </div>
              <div className="shadow-card b-border p-30 mb-15">
                <Form form={form} layout="vertical" name="pricing-guide" onFinish={onSubmit}>
                  <Row gutter={[10,10]} className="">
                    <Col xs={24} md={12}>
                      {renderInputField("First Name", "first_name", true)}
                    </Col>
                    <Col xs={24} md={12}>
                      {renderInputField("Last Name", "last_name", true)}
                    </Col>
                    <Col xs={24} md={24}>
                      {renderEmailField("Email", "email", true)}
                    </Col>
                    { isSystemFieldEnabled("phone") && (
                      <Col xs={24} md={24}>
                        { renderPhoneField("Phone", "phone", isSystemFieldRquired("phone"), false, phoneCountryCode, setPhoneCountryCode) }
                      </Col>
                    )}
                    { isSystemFieldEnabled("address") && (
                      <>
                        <Col xs={24} md={24}>
                          { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, isSystemFieldRquired("address"), onAddressCountryChange) }
                        </Col>
                        <Col xs={24} md={24}>
                          { renderGlobalAddressField(addressCountryCode, "address_line_1", isSystemFieldRquired("address")) }
                        </Col>
                        <Col xs={24} md={24}>
                          { renderGlobalSecondAddressField(addressCountryCode, "address_line_2") }
                        </Col>
                        <Col xs={24} md={24}>
                          { renderGlobalCityField(addressCountryCode, "city", isSystemFieldRquired("address")) }
                        </Col>
                        <Col xs={24} md={16}>
                          { renderGlobalStateField(addressCountryCode, "state", isSystemFieldRquired("address")) }
                        </Col>
                        <Col xs={12} md={8}>
                          { renderGlobalZipField(addressCountryCode, "zip", isSystemFieldRquired("address")) }
                        </Col>
                      </>
                    )}
                    { isSystemFieldEnabled("event_type") && (
                      <Col xs={24} md={24}>
                        { renderSearchSelectField("Event Type", "account_event_type_id", "", eventTypes, isSystemFieldRquired("event_type")) }
                      </Col>
                    )}
                    <Col xs={24} md={24}>
                      {renderDateField("Event Date", "event_date", pricingGuide.account_settings)}
                    </Col>
                    { isSystemFieldEnabled("start_end_time") && (
                      <>
                      <Col xs={12} md={8}>
                        { renderTimeField("Start Time", "start_time", isSystemFieldRquired("start_end_time"), pricingGuide.account_settings) }
                      </Col>
                      <Col xs={12} md={8}>
                        { renderTimeField("End Time", "end_time", isSystemFieldRquired("start_end_time"), pricingGuide.account_settings) }
                      </Col>
                      </>
                    )}
                     { isSystemFieldEnabled("venue") && (
                      <Col xs={24} md={24}>
                        {renderInputField("Venue", "venue_name", isSystemFieldRquired("venue"))}
                      </Col>
                    )}
                    { renderCustomFields() }
                    <Col xs={24} md={24}>
                      {renderTextAreaField("Message", "message")}
                    </Col>
                    <Col xs={24} md={24}>
                      <div className="line-1-2 mt-15 c-text-gray">I am interested in booking the following services:</div>
                      { renderFormPackages() }
                      { renderFormAddOns() }
                    </Col>
                    <Col xs={24} className="text-center">
                      { isSaving ? (
                        <button className="small-primary-button" style={{ width: 150 }}><LoadingOutlined/></button>
                      ) : (
                        <button className="small-primary-button" style={{ width: 150 }} type="submit">Submit</button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </FloatingContainer>
          </>
        )}
      </div>
      )
  }

  const renderErrorModal = () => {
    return (
      <Modal visible={isErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Select a Service</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Please select at least one package or add-on before continuing.</div>
          <button className="primary-button" type="button" onClick={() => setErrorModalVisible()}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderQuantityErrorModal = () => {
    return (
      <Modal visible={isQuantityErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setQuantityErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Select a Valid Quantity</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Please select a valid quantity for your add-ons before continuing.</div>
          <button className="primary-button" type="button" onClick={() => setQuantityErrorModalVisible()}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderSuccessModal = () => {
    return (
      <Modal visible={isSuccessModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => onSuccessClose(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Thank you!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Your request has been submitted. We will follow with next steps shortly.</div>
          <button className="primary-button" type="button" onClick={() => onSuccessClose()}>Done</button>
        </div>
      </Modal>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  if (!isFound) {
    return (
      <div className="text-center p-80">This pricing guide cannot be found. Please check the link and try again.</div>
    )
  }

  const renderHeadTags = () => {
    return (
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Pricing Guide | {pricingGuide.company_name}</title>
        <meta property="og:title" content={`Pricing Guide | ${pricingGuide.company_name}`}></meta>
      </Helmet>
    )
  }

  return (
    <div>
      { renderHeadTags() }
      { renderContent() }
      { renderErrorModal() }
      { renderQuantityErrorModal() }
      { renderSuccessModal() }
    </div>
  );
}

export default PricingGuidePage;
