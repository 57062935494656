import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FiDownload, FiCheckCircle, FiArrowDown } from "react-icons/fi";
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import ContractPreview from '../../components/contractPreview';
import ContractPDF from '../../pdfs/contractPDF';
import { isNull, get, isEmpty } from "lodash";
import { getEventContractToSign } from '../../api';
import { formatDateMedium } from '../../helpers/dateHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContractPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [contractStatus, setContractStatus] = useState("");
  const [contract, setContract] = useState({});
  const [signers, setSigners] = useState([]);
  const [currentSigner, setCurrentSigner] = useState({});
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Contract")
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.id) {
      loadPage()
    } else {
      setContractStatus("INVALID")
      setLoading(false)
    }
  }, [])

  const loadPage = async () => {
    try {
      const results = await getEventContractToSign(params.id)
      const status = get(results, "data.status", null)
      if (status == "not-found" || status == "failed") {
        setContractStatus("INVALID")
      } else if (status == "contract-expired") {
        setContractStatus("EXPIRED")
      } else if (results.data) {
        setContract(results.data)
        setSigners(results.data.signers)
        setAccountSettings(results.data.account_settings)
        const signer = results.data.signers.find(x => x.event_contract_signer_id == params.id)
        if (!isEmpty(signer)) {
          setCurrentSigner(signer)
        }
      }
      setLoading(false)
    } catch {
      setContractStatus("INVALID")
      setLoading(false)
    }
  }

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("event-contract--container")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("event-contract--container")[0].scrollTop;
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("event-contract--container")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const renderDocument = () => {
    return <ContractPDF contract={contract}/>
  }

  const renderContent = () => {
    if (contractStatus == "INVALID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This link is invalid.</div>
            <div className="mt-5 c-text-gray">Please check the link and try again.</div>
          </div>
        </FloatingContainer>
      )
    } else if (contractStatus == "EXPIRED") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This contract is expired.</div>
            <div className="mt-5 c-text-gray">Please reach out to your DJ for more information.</div>
          </div>
        </FloatingContainer>
      )
    }

    return (
      <div>
        <div className="toolbar-container hide-print">
          <PDFDownloadLink document={ renderDocument() } fileName={`contract-${moment().format("YYYYMMDD")}.pdf`}>
            <div className="toolbar-button" onClick={() => {}}>
              <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
            </div>
          </PDFDownloadLink>
        </div>
        <FloatingContainer verticalPadding={30} maxWidth={1000}>
          { currentSigner.signed && (
            <div className="bg-white border radius-8 mb-20 mh-15 p-20 c-text-gray hide-print display-flex flex-middle justify-content-center">
              <FiCheckCircle style={{ marginRight: 8, color: "#536DFE"}}/>You signed this contract on {formatDateMedium(currentSigner.signed_datetime, contract.account_settings, true)}.
            </div>
          )}
          { !currentSigner.signed && (
            <div className="bg-white border radius-8 mb-20 mh-15 p-20 text-center">
              <div className="fw-700">Please review and sign this document.</div>
              { !isNull(contract.expiration_datetime) && (
                <div className="mt-5 c-text-gray">This document will expire on {formatDateMedium(contract.expiration_datetime, contract.account_settings, true)}</div>
              )}
              <HashLink to={`#signature-lines`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
                <button className="small-primary-button mt-15 display-flex flex-middle"><FiArrowDown style={{ marginRight: 10, fontSize: 18 }}/>Add Signature</button>
              </HashLink>
            </div>
          )}
          <div className="print-card">
            <ContractPreview 
              contract={contract} 
              signers={signers} 
              currentSigner={currentSigner}
              reload={loadPage}
              accountSettings={accountSettings}
            />
          </div>
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh' }}>
      { renderContent() }
    </div>
  );
}

export default ContractPage;
