import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import { isArray, isNull, isEmpty } from 'lodash';
import moment from 'moment';
import { getPricingGuideRequest } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import { formatEventDateLong, formatDateTimeLong, formatEventDateMedium, formatTime } from '../../../helpers/dateHelper';
import { getFormattedPhoneNumber } from '../../../helpers/addressHelper';
import { UserOutlined } from '@ant-design/icons';
import StatusTag from '../../../components/statusTag';

const BookingRequestPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [bookingRequest, setBookingRequest] = useState({});
  const [staffAvailability, setStaffAvailability] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  useDocumentTitle("Booking Request")
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const navigate = useNavigate();

  const params = useParams();

  const requestId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const requestResults = await sendRequest(getPricingGuideRequest(requestId));
      setBookingRequest(requestResults)
      setStaffAvailability(isArray(requestResults.staff_availability) ? requestResults.staff_availability : [])
      setCustomFields(isArray(requestResults.custom_fields) ? requestResults.custom_fields : [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/booking-requests")}>
            Booking Requests
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Details
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ bookingRequest.first_name } { bookingRequest.last_name }</div>
        <div className="c-text-gray">{ formatEventDateLong(bookingRequest.event_date, accountSettings) }</div>
      </div>
    )
  }

  const renderSelectedAddOn = (addOn, index) => {
    var price = addOn.allow_quantity ? `${addOn.quantity} x ${addOn.price}` : addOn.price
    return (
      <div key={index} className="ml-5">{ addOn.add_on_name } <span className="c-text-gray">({ price })</span></div>
    )
  }

  const renderCustomField = (field, index) => {
    var answer = field.answer;
    if (field.type == "checkbox") {
      answer = field.answer.replace("::",", ")
    } else if (field.type == "date" && field.answer != "" && moment(field.answer, "YYYY-MM-DD", true).isValid()) {
      answer = formatEventDateMedium(field.answer, accountSettings)
    }
    if (answer == "") {
      answer = "--"
    }
    return (
      <Col xs={24} sm={12} md={24} lg={12} key={index}>
        <div className="fs-14 fw-700">{field.label}</div>
        <div className="fs-14 fw-400">{ answer }</div>
      </Col>
    )
  }

  const renderStaffAvailableRow = (staff, index) => {
    var status = ""
    var statusColor = null
    if (staff.events.length > 0) {
      status = "BOOKED"
      statusColor = "red"
    } else if (staff.time_off.length > 0) {
      status = "TIME OFF"
      statusColor = "gray"
    } else {
      status = "AVAILABLE"
      statusColor = "green"
    }
    return (
      <>
        <Row  gutter={[10,10]} align="middle" key={index}>
          <Col>
            { !isNull(staff.photo) ? (
              <div className="small-profile-photo" style={{ backgroundImage: `url(${staff.photo})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle small"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1} style={{ lineHeight: 1.4 }}>
            <div className="fs-14 fw-700">{staff.first_name} {staff.last_name}</div>
          </Col>
          <Col flex={0}>
            <StatusTag status={status} color={statusColor}/>
          </Col>
        </Row>
        { index < staffAvailability.length - 1 && (
          <div className="mb-10"></div>
        )}
      </>
    )
  }

  const renderAvailabilityBox = () => {
    const isSingleOp = staffAvailability.length == 1

    var message = ""
    var available = false
    if (isSingleOp) {
      const eventCount = Number(bookingRequest.event_count)
      const eventTerm = eventCount == 1 ? "event" : "events"
      if (eventCount > 0) {
        message = `You have ${eventCount} ${eventTerm} on this day`
      } else {
        message = "You are available!"
        available = true
      }
    } else {
      const numAvailableStaff = staffAvailability.reduce((x,y) => {
        const hasEvents = y.events.length > 0
        const hasTimeOff = y.time_off.length > 0
        const increment = !hasEvents && !hasTimeOff ? 1 : 0
        return x + increment;
      }, 0)
      if (numAvailableStaff > 0) {
        const staffTerm = numAvailableStaff == 1 ? "staff member" : "staff members"
        message = `You have ${numAvailableStaff} available ${staffTerm} on this day!`
        available = true
      } else {
        message = "You have no available staff members on this day"
      }
    }

    return (
      <div className={`message-box ${available ? "success" : "warning"}`}>
        <div className="message-text text-center">{message}</div>
      </div>
    )
  }

  const renderBookingRequest = () => {
    return (
      <>
        <div className="shadow-card mb-20 ph-15 pb-15">
          <div className="fs-14 text-center fw-700 pt-20">Event Date</div>
          <div className="fs-18 text-center pb-20">{ formatEventDateLong(bookingRequest.event_date, accountSettings) }</div>
          { renderAvailabilityBox() }
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 mb-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Details</div>
            </Col>
          </Row>
          <div className="ph-20 pb-20">
            <Row gutter={[15,15]}>
              <Col xs={24} sm={12} md={24} lg={12}>
                <div className="fs-14 fw-700">Name</div>
                <div className="fs-14 fw-400">{ bookingRequest.first_name } { bookingRequest.last_name }</div>
              </Col>
              <Col xs={24} sm={12} md={24} lg={12}>
                <div className="fs-14 fw-700">Email</div>
                <div className="fs-14 fw-400">{ bookingRequest.email }</div>
              </Col>
              { !isNull(bookingRequest.phone) && (
                <Col xs={24} sm={12} md={24} lg={12}>
                  <div className="fs-14 fw-700">Phone</div>
                  <div className="fs-14 fw-400">{ getFormattedPhoneNumber(bookingRequest.phone) }</div>
                </Col>
              )}
              { !isNull(bookingRequest.address_line_1) && (
                <Col xs={24} sm={12} md={24} lg={12}>
                  <div className="fs-14 fw-700">Address</div>
                  <div className="fs-14 fw-400">{ bookingRequest.address_line_1 } { bookingRequest.address_line_2 }</div>
                  <div className="fs-14 fw-400">{ bookingRequest.city }, { bookingRequest.state } { bookingRequest.zip }</div>
                </Col>
              )}
              { !isNull(bookingRequest.account_event_type_name) && (
                <Col xs={24} sm={12} md={24} lg={12}>
                  <div className="fs-14 fw-700">Event Type</div>
                  <div className="fs-14 fw-400">{ bookingRequest.account_event_type_name }</div>
                </Col>
              )}
              { !isNull(bookingRequest.start_time) && (
                <Col xs={24} sm={12} md={24} lg={12}>
                  <div className="fs-14 fw-700">Start / End Time</div>
                  <div className="fs-14 fw-400">{ formatTime(bookingRequest.start_time, accountSettings) } - { formatTime(bookingRequest.end_time, accountSettings) }</div>
                </Col>
              )}
              { !isNull(bookingRequest.venue_name) && (
                <Col xs={24} sm={12} md={24} lg={12}>
                  <div className="fs-14 fw-700">Venue</div>
                  <div className="fs-14 fw-400">{ bookingRequest.venue_name }</div>
                </Col>
              )}
              { customFields.map((x,i) => renderCustomField(x,i)) }
              <Col xs={24}>
                <div className="fs-14 fw-700">Message</div>
                <div className="fs-14 fw-400">{ !isNull(bookingRequest.message) ? bookingRequest.message : "--" }</div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 mb-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Requested Services</div>
            </Col>
          </Row>
          <div className="ph-20 pb-20">
            <Row gutter={[15,15]}>
              <Col xs={24}>
                <div className="fs-14 fw-700">Packages</div>
                { bookingRequest.selected_services.packages.map((x,i) => (
                  <div className="ml-5" key={i}>{ x.package_name } <span className="c-text-gray">({ x.price })</span></div>
                ))}
                { bookingRequest.selected_services.packages.length == 0 && (
                  <div className="ml-5">--</div>
                )}
                <div className="fs-14 fw-700 mt-15">Add-Ons</div>
                { bookingRequest.selected_services.add_ons.map((x,i) => renderSelectedAddOn(x,i))}
                { bookingRequest.selected_services.add_ons.length == 0 && (
                  <div className="ml-5">--</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 mb-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Availability</div>
            </Col>
          </Row>
          <div className="ph-20 pb-20">
            { staffAvailability.map((x,i) => renderStaffAvailableRow(x,i) ) }
          </div>
        </div>
        <div className="shadow-card mb-20 p-20 text-center">
          <button className="small-primary-button" onClick={() => navigate(`/admin/events/new?request=${requestId}`)}>Create Event</button>
        </div>
        <div className="fs-12 c-text-gray text-center mb-15">Received on: {formatDateTimeLong(bookingRequest.create_datetime, accountSettings)} ({moment(bookingRequest.create_datetime).fromNow()})</div>
      </>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderBookingRequest() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default BookingRequestPage;
