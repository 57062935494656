import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Modal, Input, Checkbox } from 'antd';
import { isArray, isEmpty, get, isUndefined } from "lodash";
import { getClientSigners, getAdminSigners } from '../helpers/contractHelper';
import ContractStatus from '../constants/contractStatus';
import { MdClose, MdOutlineClose } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { signEventContract } from '../api';
import {
  getDiscountMessage,
  getNumericCurrency
} from '../helpers/contractHelper';
import { formatDate } from '../helpers/dateHelper';

const ContractPreview = props => {

  const { contract = {}, signers = [], currentSigner = {}, reload = () => {}, accountSettings = {} } = props;

  const [isSignatureModalVisible, setSignatureModalVisible] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [signature, setSignature] = useState("");
  const [isTermsAccepted, setTermsAccepted] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [signingErrorMessage, setSigningErrorMessage] = useState("");

  const startSigning = () => {
    setSigningErrorMessage("")
    setSignature("")
    setTermsAccepted(false)
    setSignatureModalVisible(true)
  }

  const onTermsAcceptedChange = (value) => {
    setTermsAccepted(value)
    setSigningErrorMessage("")
  }
  
  const acceptDocument = async () => {
    if (isEmpty(signature)) {
      setSigningErrorMessage("Please enter a signature to continue.")
      return
    } else if (!isTermsAccepted) {
      setSigningErrorMessage("Please accept the terms to continue")
      return
    } 
    try {
      setSaving(true)
      const body = {
        event_contract_signer_id: currentSigner.event_contract_signer_id,
        event_contract_id: contract.event_contract_id,
        signature: signature,
        signed_datetime: moment().format()
      }
      await signEventContract(body)
      await reload()
      setSaving(false)
      setSignatureModalVisible(false)
      setConfirmationModalVisible(true)
    } catch {
      setSaving(false)
    }
  }

  const renderNode = (node) => {
    if (node.type == "text") {
      var boldClass = ""
      var italicClass = ""
      var underlineClass = ""
      var strikeClass = ""
      if (isArray(node.marks)) {
        boldClass = !isEmpty(node.marks.find(x => x.type == "bold")) ? "fw-700" : ""
        italicClass = !isEmpty(node.marks.find(x => x.type == "italic")) ? "f-italic" : ""
        underlineClass = !isEmpty(node.marks.find(x => x.type == "underline")) ? "underlined" : ""
        strikeClass = !isEmpty(node.marks.find(x => x.type == "strike")) ? "strikethrough" : ""
      }
      return <span className={`${boldClass} ${italicClass} ${underlineClass} ${strikeClass}`}>{node.text}</span>;
    } else if (node.type == "merge-tag") {
      const value = get(node, "attrs.value", "")
      return <span>{value}</span>
    }
    return null;
  }

  const renderInlineContent = (content) => {
    if (isArray(content)) {
      return content.map((x,i) => renderNode(x,i))
    }
    return null;
  }

  const renderHeading = (block) => {
    const level = get(block, "attrs.level", 1)
    const textAlign = get(block, "attrs.textAlign", "left")

    var fontSize = "fs-14";
    if (level == 1) {
      fontSize = "fs-24";
    } else if (level == 2) {
      fontSize = "fs-20";
    } else if (level == 3) {
      fontSize = "fs-16";
    } else if (level == 4) {
      fontSize = "fs-14";
    }

    return (
      <div className={`fw-700 ${fontSize} mb-10 text-${textAlign}`}>
        { renderInlineContent(block.content) }
      </div>
    )
  }

  const renderParagraph = (block) => {
    if (!isUndefined(block.content)) {
      const textAlign = get(block, "attrs.textAlign", "left")
      return (
        <div className={`fs-14 mb-10 text-${textAlign}`}>
          { renderInlineContent(block.content) }
        </div>
      )
    } else {
      return <div className="p-10"></div>
    }
  }

  const renderListItem = (item) => {
    if (isArray(item.content)) {
      return (
        <li className={`fs-14 mb-10`}>
          { item.content.map((x,i) => renderBlock(x,i))}
        </li>
      )
    }
  }

  const renderOrderedList = (block) => {
    if (isArray(block.content)) {
      return (
        <ol className={`fs-14 mb-10`}>
          { block.content.map((x,i) => renderListItem(x,i))}
        </ol>
      )
    }
  }

  const renderBulletList = (block) => {
    if (isArray(block.content)) {
      return (
        <ul className={`fs-14 mb-10`}>
          { block.content.map((x,i) => renderListItem(x,i))}
        </ul>
      )
    }
  }

  const renderEventPackage = (item, index) => {
    return (
      <div className="display-flex flex-row pv-10 ph-10 b-border" key={index}>
        <div className="flex-1">
          <div>{item.package_name}</div>
          { item.discount && (
            <div className="c-text-gray fs-12 ml-5">{getDiscountMessage(item)}</div>
          )}
        </div>
        { item.discount ? (
          <>
            <div className="flex-0 text-right nowrap">
              <div className="strikethrough c-text-gray">{item.price}</div>
              <div>{item.total}</div>
            </div>
          </>
        ) : (
          <div className="flex-0 nowrap">{item.price}</div>
        )}
      </div>
    )
  }

  const renderEventAddOn = (item, index) => {
    return (
      <div className="display-flex flex-row pv-10 ph-10 b-border" key={index}>
        <div className="flex-1">
          <span className="">{item.add_on_name} </span>
          <span className="c-text-gray">({item.quantity} x {item.price})</span>
          { item.discount && (
            <div className="c-text-gray fs-12 ml-5">{getDiscountMessage(item)}</div>
          )}
        </div>
        { item.discount ? (
          <>
            <div className="flex-0 text-right nowrap">
              <div className="strikethrough c-text-gray">{item.sub_total}</div>
              <div>{item.total}</div>
            </div>
          </>
        ) : (
          <div className="flex-0 nowrap">{item.total}</div>
        )}
      </div>
    )
  }
  
  const renderEventServices = (block) => {
    const data = !isUndefined(block.data) ? block.data : {};
    const packages = isArray(data.packages) ? data.packages : [];
    const addOns = isArray(data.add_ons) ? data.add_ons : [];
    const hasTaxes = getNumericCurrency(data.tax) != 0
    return (
      <div className="mb-30 mt-20 t-border">
        { packages.map((x,i) => renderEventPackage(x,i))}
        { addOns.map((x,i) => renderEventAddOn(x,i))}
        { hasTaxes && (
          <div className="display-flex flex-row pv-10 ph-10">
            <div className="flex-1 text-right fw-700">Subtotal:</div>
            <div className="flex-0 text-right">
            <div style={{ width: 200 }}>{data.subtotal}</div>
            </div>
          </div>
        )}
        { hasTaxes && (
          <div className="display-flex flex-row pb-10 ph-10 b-border">
            <div className="flex-1 text-right fw-700">{ accountSettings.tax_label ?? "Tax"} ({data.tax_rate}):</div>
            <div className="flex-0 text-right">
              <div style={{ width: 200 }}>{ data.tax }</div>
            </div>
          </div>
        )}
        <div className="display-flex flex-row t-border pv-10 ph-10">
          <div className="flex-1 text-right fw-700">Total:</div>
          <div className="flex-0 text-right fw-700">
            <div style={{ width: 200 }}>{ data.total_price }</div>
          </div>
        </div>
      </div>
    )
  }

  const renderBlock = (block, index) => {
    if (block.type == "heading") {
      return renderHeading(block)
    } else if (block.type == "paragraph") {
      return renderParagraph(block)
    } else if (block.type == "orderedList") {
      return renderOrderedList(block)
    } else if (block.type == "bulletList") {
      return renderBulletList(block)
    } else if (block.type == "event-services") {
      return renderEventServices(block)
    }
    return null
  }

  const renderSigner = (signer, index) => {
    const isCurrentSigner = currentSigner.event_contract_signer_id == signer.event_contract_signer_id
    if (signer.signed) {
      return (
        <div className="mt-30" key={index}>
          <div className="signature-field-readonly">{signer.signature}</div>
          <div className="signature-line"></div>
          <div className="mt-5 mh-5 display-flex flex-row flex-middle">
            <div className="flex-1">{signer.first_name} {signer.last_name}</div>
            { !isEmpty(signer.signed_datetime) && (
              <div className="c-text-gray fs-12">Signed on {formatDate(signer.signed_datetime, contract.account_settings, true)}</div>
            )}
          </div>
          <div className="ml-5 c-text-gray">{signer.email}</div>
        </div>
      )
    } else if (isCurrentSigner) {
      return (
        <div className="mt-30" key={index}>
          <div className="signature-field" onClick={() => startSigning()}>
            <div className="flex-0 display-flex"><MdClose style={{ fontSize: 20, marginRight: 5 }}/></div> 
            <div>Sign here</div>
          </div>
          <div className="mt-5 ml-5">{signer.first_name} {signer.last_name}</div>
          <div className="ml-5 c-text-gray">{signer.email}</div>
        </div>
      )
    } else {
      return (
        <div className="mt-30" key={index}>
          <div style={{height: 40}}/>
          <div className="signature-line"></div>
          <div className="mt-5 ml-5">{signer.first_name} {signer.last_name}</div>
          <div className="ml-5 c-text-gray">{signer.email}</div>
        </div>
      )
    }
  }

  const renderSignatures = () => {
    return (
      <div className="mt-50 mb-20" id="signature-lines">
        { getClientSigners(signers).map((x,i) => renderSigner(x,i))}
        { getAdminSigners(signers).map((x,i) => renderSigner(x,i))}
      </div>
    )
  }

  const renderContract = () => {
    if (!isEmpty(contract.content) && isArray(contract.content.content)) {
      return contract.content.content.map((x,i) => renderBlock(x,i))
    }
    return null
  }

  const renderSignatureModal = () => {
    return (
      <Modal visible={isSignatureModalVisible} width={600} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Signature</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setSignatureModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-15">
          <Col xs={24}>
            <div className="c-text-gray ml-5">Type your full name below</div>
          </Col>
          <Col xs={24}>
            <div className="signature-pad">
              <div className="text-right c-text-gray p-10 mb-20 cursor-pointer" onClick={() => setSignature("")}>Clear</div>
              <Input size="large" value={signature} onChange={(e) => setSignature(e.target.value)}/>
            </div>
          </Col>
          <Col xs={24}>
            <div className="mt-20 fs-12">
              <Checkbox value={isTermsAccepted} onChange={(e) => onTermsAcceptedChange(e.target.checked)}>By selecting Accept and Sign, I understand that I am signing a legally binding contract.</Checkbox>
            </div>
          </Col>
          <Col xs={24}>
            <div className="mt-10 text-center c-red">{signingErrorMessage}</div>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          { isSaving ? (
            <button className="primary-button"><LoadingOutlined/></button>
          ) : (
            <button className="primary-button" onClick={() => acceptDocument()}>Accept and Sign</button>
          )}
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setSignatureModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmationModal = () => {
    var message = "You have officially signed this document. Once all parties have added their signature, you will receive a confirmation email."
    if (contract.status == ContractStatus.COMPLETED) {
      message = "You have officially signed this document. All parties have added their signature, so this document is complete."
    }
    return (
      <Modal visible={isConfirmationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Thank you!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">{message}</div>
          <button className="primary-button" type="button" onClick={() => setConfirmationModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  return (
    <>
      { renderContract() }
      { renderSignatures() }
      { renderSignatureModal() }
      { renderConfirmationModal() }
    </>
  )
}
export default ContractPreview;