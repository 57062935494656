import React, { useEffect, useState } from "react";
import { isEmpty, startsWith } from "lodash";
import moment from "moment";
import { Row, Col, Grid } from 'antd';
import InvoiceSummary from './invoiceSummary';
import { formatCurrencyString } from '../helpers/contractHelper';
import { formatDateShort, formatEventDateShort } from '../helpers/dateHelper';

const InvoicePreview = props => {
  const { invoice = {}, ghost = false, accountSettings = {} } = props;

  const [eventFinancialSummary, setEventFinancialSummary] = useState({});
  const [billToFirstName, setBillToFirstName] = useState("");
  const [billToLastName, setBillToLastName] = useState("");
  const [billToEmail, setBillToEmail] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    setEventFinancialSummary(invoice.summary)
    if (invoice.bill_to_user && !isEmpty(invoice.bill_to_user)) {
      const user = invoice.bill_to_user
      setBillToFirstName(user.first_name)
      setBillToLastName(user.last_name)
      setBillToEmail(user.email)
    }
    // Dates
    if (invoice.invoice_date) {
      setInvoiceDate(formatDateShort(invoice.invoice_date, accountSettings, true))
    }
    if (invoice.due_date) {
      setDueDate(formatDateShort(invoice.due_date, accountSettings, true))
    }
  }, []);

  const onPayNow = () => {
    if (invoice.payment_link) {
      const link = startsWith(invoice.payment_link, "http") ? invoice.payment_link : `https://${invoice.payment_link}`
      window.open(link, "_blank")
    }
  }

  const renderNotes = () => {
    if (!isEmpty(invoice.notes)) {
      return (
        <>
          <div className="fs-14 fw-700 mb-5">Notes</div>
          <div className="line-breaks">{invoice.notes}</div>
        </>
      )
    }
    return null;
  }

  const renderDueTodayBox = () => {
    return (
      <div className="pv-15 ph-15 bg-gray radius-8 text-center" style={{ display: "inline-block", width: 250 }}>
        <div className="fs-14 fw-700 mb-5">Amount Due</div>
        <div className="fs-24 fw-500 mb-10 c-blue">{formatCurrencyString(invoice.amount_due, invoice.currency)}</div>
        { invoice.include_payment_link && !isEmpty(invoice.payment_link) && (
          <button className="small-primary-button" style={{ width: '100%'}} onClick={() => onPayNow()}>Pay Invoice</button>
        )}
      </div>
    )
  }

  const renderLogo = () => {
    if (invoice.logo) {
      return (
        <img src={invoice.logo} style={{ width: 200 }}/>
      )
    } else {
      return (
        <div className="fs-14 fw-700">{ invoice.company_name }</div>
      )
    }
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const containerClass = ghost ? "" : `shadow-card ${paddingClass} mt-15`
    return (
      <>
        <div className={containerClass}>
          { screens.md ? (
            <div className="flex-row">
              <div className="flex-1">
                { renderLogo() }
              </div>
              <div className="flex-1">
                <div className="fs-32 fw-500 text-right">INVOICE</div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="mt-10">
                { renderLogo() }
              </div>
              <div className="mt-10">
                <div className="fs-32 fw-500 text-center">INVOICE</div>
              </div>
            </div>
          )}
          <Row className="mt-50 mb-50" gutter={[30,30]}>
            <Col xs={24} md={12}>
              <div className="fs-14 fw-700">Bill To:</div>
              <div className="fs-14">{billToFirstName} {billToLastName}</div>
              <div className="fs-14">{billToEmail} </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex-row">
                <div className="flex-0" style={{ minWidth: 120 }}>
                  <div className="fs-14 fw-700">Invoice #:</div>
                </div>
                <div className="flex-1">
                  <div className="">{invoice.invoice_number}</div>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-0" style={{ minWidth: 120 }}>
                  <div className="fs-14 fw-700">Event Date:</div>
                </div>
                <div className="flex-1">
                  <div className="">{formatEventDateShort(invoice.event_date, accountSettings)}</div>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-0" style={{ minWidth: 120 }}>
                  <div className="fs-14 fw-700">Invoice Date:</div>
                </div>
                <div className="flex-1">
                  <div className="">{invoiceDate}</div>
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-0" style={{ minWidth: 120 }}>
                  <div className="fs-14 fw-700">Due Date:</div>
                </div>
                <div className="flex-1">
                  <div className="">{dueDate}</div>
                </div>
              </div>
            </Col>
          </Row>

          <InvoiceSummary summary={eventFinancialSummary} accountSettings={accountSettings}/>

          { screens.md ? (
            <Row className="mt-30" gutter={[15,15]}>
              <Col xs={14}>
                { renderNotes() }
              </Col>
              <Col xs={10} className="text-right">
                { renderDueTodayBox() }
              </Col>
            </Row>
          ) : (
            <Row className="mt-30" gutter={[30,30]}>
              <Col xs={24}>
                { renderNotes() }
              </Col>
              <Col flex={1}></Col>
              <Col flex={0}>
                { renderDueTodayBox() }
              </Col>
            </Row>
          )}
        </div>
      </>
    )
  }

  return renderContent()
};

export default InvoicePreview;
