import React from 'react';
import { isEmpty, isNull } from "lodash";

const StatusTag = props => {
  const { status = "", size = "", className = "", color = null } = props;

  if (isEmpty(status)) {
    return null;
  }

  const renderContent = () => {
    var statusColor = ""
    if (!isNull(color)) {
      statusColor = color
    } else if (status == "DRAFT") {
      statusColor = "gray"
    } else if (status == "PAID" || status == "SENT" || status == "COMPLETED" || status == "APPROVED") {
      statusColor = "green"
    } else if (status == "UNPAID" || status == "CANCELLED") {
      statusColor = "gray"
    } else if (status == "PAST_DUE" || status == "HARD_BOUNCE" || status == "SOFT_BOUNCE" || status == "PARTIAL_BOUNCE" || status == "REJECTED") {
      statusColor = "red"
    } else if (status == "PENDING" || status == "AWAITING_SEND" || status == "AWAITING_SIGNATURE") {
      statusColor = "yellow"
    }
    const statusName = !isEmpty(status) ? status.replace("_", " ") : ""
    return (
      <div className={`status-tag ${statusColor} ${size} ${className}`}>{statusName}</div>
    )
  }

  return renderContent()
};

export default StatusTag;
