import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { isArray } from "lodash";
import qs from "qs";
import { pdf, PDFViewer, Document } from '@react-pdf/renderer';
import { Grid } from 'antd';
import { saveAs } from 'file-saver';
import LoadingSpinner from '../../../components/loading';
import EventSheetPDF from '../../../pdfs/eventSheetPDF';
import MusicSelectionsPDF from '../../../pdfs/musicSelectionsPDF';
import TimelinePDF from '../../../pdfs/timelinePDF';
import QuestionnairesPDF from '../../../pdfs/questionnairesPDF';
import { 
  getEventSheetDetails, 
  getMusicSelectionDetails,
  getTimelineDetails,
  getQuestionnaireDetails 
} from '../../../api';
import { formatEventDateLong } from '../../../helpers/dateHelper';

const AllEventPdfPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [eventSheetDetails, setEventSheetDetails] = useState({});
  const [musicSelectionDetails, setMusicSelectionDetails] = useState({});
  const [timelineDetails, setTimelineDetails] = useState({});
  const [questionnaireDetails, setQuestionnaireDetails] = useState({});
  const [showEventSheet, setShowEventSheet] = useState(false);
  const [showMusicSelections, setShowMusicSelections] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showQuestionnaires, setShowQuestionnaires] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [accountSettings, setAccountSettings] = useState("");

  const params = useParams();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const location = useLocation()

  useEffect(() => {
    loadPage()
  }, [])

  const loadPage = async () => {
    try {
        setLoading(true)

        const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

        const selectedPDFS = isArray(queryStrings.selected) ? queryStrings.selected : []
        const shouldShowEventSheet = selectedPDFS.includes("event-sheet")
        const shouldShowMusicSelections = selectedPDFS.includes("music-selections")
        const shouldShowTimeline = selectedPDFS.includes("timline")
        const shouldShowQuestionnaires = selectedPDFS.includes("questionnaires")

        setShowEventSheet(shouldShowEventSheet)
        setShowMusicSelections(shouldShowMusicSelections)
        setShowTimeline(shouldShowTimeline)
        setShowQuestionnaires(shouldShowQuestionnaires)

        // Event Sheet
        if (shouldShowEventSheet) {
          const eventSheetResponse = await getEventSheetDetails(params.id)
          setEventSheetDetails(eventSheetResponse.data)
          setEventName(eventSheetResponse.data.event_name)
          setEventDate(eventSheetResponse.data.event_date)
          setAccountSettings(eventSheetResponse.data.account_settings)
        }

        // Music Selections
        if (shouldShowMusicSelections) {
          const musicSelectionsResponse = await getMusicSelectionDetails(params.id)
          setMusicSelectionDetails(musicSelectionsResponse.data)
          setEventName(musicSelectionsResponse.data.event_name)
          setEventDate(musicSelectionsResponse.data.event_date)
          setAccountSettings(musicSelectionsResponse.data.account_settings)
        }

        // Timeline
        if (shouldShowTimeline) {
          const timelineResponse = await getTimelineDetails(params.id)
          setTimelineDetails(timelineResponse.data)
          setEventName(timelineResponse.data.event_name)
          setEventDate(timelineResponse.data.event_date)
          setAccountSettings(timelineResponse.data.account_settings)
        }

        // Questionnaires
        if (shouldShowQuestionnaires) {
          const questionnaireResponse = await getQuestionnaireDetails(params.id)
          setQuestionnaireDetails(questionnaireResponse.data)
          setEventName(questionnaireResponse.data.event_name)
          setEventDate(questionnaireResponse.data.event_date)
          setAccountSettings(questionnaireResponse.data.account_settings)
        }

        setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setError(true)
    }
  }

  const downloadPDF = async () => {
    try {
      const blob = await pdf(( renderPDF() )).toBlob();
      saveAs(blob, `event-${eventDate}.pdf`)
    } catch {

    }
  }

  const renderPDF = () => {
    return (
      <Document>
        { showEventSheet && <EventSheetPDF event={eventSheetDetails}/>}
        { showMusicSelections && <MusicSelectionsPDF event={musicSelectionDetails}/>}
        { showTimeline && <TimelinePDF event={timelineDetails}/>}
        { showQuestionnaires && <QuestionnairesPDF event={questionnaireDetails}/>}
      </Document>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="mt-80 text-center p-20">
        <div className="fw-700">
          There was an error loading this PDF.
        </div>
        Please try again later.
      </div>
    )
  }

  if (!screens.md) {
    return (
      <div className="mt-80 text-center ph-20 mt-50">
        <div className="fw-700 fs-20">Event Details</div>
        <div className="fw-700 fs-16 mt-20">
          { eventName }
        </div>
        <div className="fs-16 c-text-gray">{formatEventDateLong(eventDate, accountSettings)}</div>
        <button className="primary-button mt-30" style={{width: 200, borderRadius: 0 }} onClick={downloadPDF}>Download PDF</button>
      </div>
    )
  }

  return (
    <> 
      <div className="pdf-viewer--container">
        <div className="pdf-viewer--header text-right">
          <button className="small-primary-button m-15" style={{ borderRadius: 0 }} onClick={downloadPDF}>Download PDF</button>
        </div>
        <PDFViewer className="pdf-viewer--body" showToolbar={false}>
          {renderPDF()}
        </PDFViewer>
      </div>
    </>
  )
}

export default AllEventPdfPage;
