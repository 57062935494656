import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isArray, isEmpty, isNull } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansSemiBold from '../fonts/OpenSans/OpenSans-SemiBold.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { formatEventDateLong } from '../helpers/dateHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansSemiBold, fontWeight: 600 },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const MusicSelectionsPDF = (props) => {
  const { event } = props
  const { playlists = [], special_event_sections = [], genres = [], event_name = "", event_date = null, account_settings = {} } = event

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    row: {
      flexDirection: 'row'
    },
    col: {
      flex: 1
    },
    section: {
      marginBottom: 20
    },
    sectionContainer: {
      marginBottom: 10
    },
    sectionHeader: {
      borderBottom: "2px solid #CCC",
      marginBottom: 5
    },
    sectionHeaderText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingBottom: 5
    },
    sectionSubHeader: {
      marginBottom: 0,
      backgroundColor: "#F7F7F7",
      marginLeft: 10
    },
    sectionSubHeaderText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingVertical: 5
    },
    header: {
      marginBottom: 20
    },
    headerText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center"
    },
    subTitleText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 10
    },
    dateText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      textAlign: "center"
    },
    specialEventRow: {
      borderBottom: "1px solid #e8e8e8",
      marginLeft: 15,
      paddingVertical: 5
    },
    specialEventName: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    songText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      fontWeight: 600,
      paddingHorizontal: 5,
      paddingVertical: 1
    },
    songTitle: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777"
    },
    notesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      backgroundColor: "#F7F7F7",
      paddingVertical: 4,
      paddingHorizontal: 5,
      marginLeft: 5,
      marginTop: 3,
      borderRadius: 4
    },
    genreText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      fontWeight: 600,
      paddingHorizontal: 5,
      marginTop: 2
    },
    emptyText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingHorizontal: 5,
      marginTop: 5
    },
  });

  const renderDocumentSong = (song, index, songs) => {
    const marginBottom = 0 // index < songs.length - 1 ? 4 : 0
    return (
      <View style={{ marginBottom: marginBottom }} key={index}>
        <Text style={styles.songText}>{song.title} - {song.artist}</Text>
        { !isEmpty(song.notes) && (
          <Text style={styles.notesText}>{song.notes}</Text>
        )}
      </View>
    )
  }

  const renderDocumentPlaylist = (playlist, index) => {
    return (
      <View style={styles.sectionContainer} key={index}>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>{playlist.playlist_name}</Text>
        </View>
        { isArray(playlist.songs) && playlist.songs.length > 0 ? (
          <View style={{ paddingLeft: 10 }}>
            { playlist.songs.map((x,i) => renderDocumentSong(x,i,playlist.songs)) }
          </View>
        ) : (
          <View style={{ paddingLeft: 10 }}>
            <Text style={styles.emptyText}>No songs selected</Text>
          </View>
        )}
      </View>
    )
  }

  const renderDocumentSpecialEvent = (specialEvent, index) => {
    const eventSongs = isArray(specialEvent.songs) ? specialEvent.songs : []
    return (
      <View style={styles.specialEventRow} key={index}>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.specialEventName}>{ specialEvent.special_event_name }</Text>
          </View>
          <View style={styles.col}>
            { eventSongs.map((x,i) => renderDocumentSong(x,i,eventSongs))}
            { eventSongs.length == 0 && (
              <Text style={styles.songText}>--</Text>
            )}
          </View>
        </View>
      </View>
    )
  }

  const renderDocumentSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionActiveSpecialEvents = sectionSpecialEvents.filter(x => x.active && x.songs_allowed);
    return (
      <View style={styles.sectionContainer} key={index}>
        <View style={styles.sectionSubHeader}>
          <Text style={styles.sectionSubHeaderText}>{section.section_name}</Text>
        </View>
        { sectionActiveSpecialEvents.map((x,i) => renderDocumentSpecialEvent(x,i))}
        { sectionActiveSpecialEvents.length == 0 && (
          <Text style={[styles.emptyText, { paddingLeft: 10 }]}>No special events selected</Text>
        )}
      </View>
    )
  }

  const renderDocumentSpecialEvents = () => {
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Special Events</Text>
        </View>
        { special_event_sections.map((x,i) => renderDocumentSection(x,i)) }
      </View>
    )
  }

  const renderDocumentGenre = (genre, index) => {
    return (
      <View key={index}>
        <Text style={styles.genreText}>{ genre.genre_name }</Text>
        { !isEmpty(genre.notes) && (
          <Text style={styles.notesText}>{ genre.notes }</Text>
        )}
      </View>
    )
  }

  const renderDocumentFavoriteGenres = () => {
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Favorite Genres</Text>
        </View>
        { genres.map((x,i) => renderDocumentGenre(x,i)) }
        { genres.length == 0 && (
          <Text style={styles.emptyText}>No genres selected</Text>
        )}
      </View>
    )
  }

  const renderDocumentPlaylists = () => {
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Playlists</Text>
        </View>
        { playlists.map((x,i) => renderDocumentPlaylist(x,i)) }
        { playlists.length == 0 && (
          <Text style={styles.emptyText}>No playlists exist for this event</Text>
        )}
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerText}>{ event_name }</Text>
          { !isNull(event_date) && (
            <Text style={styles.dateText}>{ formatEventDateLong(event_date, account_settings) }</Text>
          )}
          <Text style={styles.subTitleText}>Music Selections</Text>
        </View>
        { renderDocumentSpecialEvents() }
        { renderDocumentFavoriteGenres() }
        { renderDocumentPlaylists() }
      </Page>
    )
  }

  return renderDocument()
}

export default MusicSelectionsPDF;
