import React from 'react';
import { isArray } from "lodash";
import { Row, Col, Grid } from 'antd';
import { getNumericCurrency } from '../helpers/contractHelper';

const InvoiceSummary = props => {
  const { summary = {}, accountSettings = {} } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const getDiscountText = (item) => {
    if (item.discount_type == "PERCENTAGE") {
      return `Discount applied (${item.discount_percentage})`
    } else {
      return "Discount applied"
    }
  }

  const renderSummaryHeader = () => {
    if (screens.md) {
      return (
        <Row align="middle" className="pv-15 t-border b-border bg-gray ph-15">
          <Col xs={12}>
            <div className="fs-12 fw-700">ITEM</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">QTY</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">PRICE</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row align="middle" className="pv-15 t-border b-border bg-gray ph-15">
          <Col xs={12}>
            <div className="fs-12 fw-700">ITEM</div>
            <div className="fs-12 fw-700">QTY / PRICE</div>
          </Col>
          <Col xs={12}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    }
  }
  
  const renderPackageRow = (eventPackage, index) => {
    if (screens.md) {
      return (
        <Row className="pv-15 b-border ph-15" key={index}>
          <Col xs={12}>
            <div className="fs-14 fw-500">{eventPackage.package_name}</div>
            { eventPackage.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(eventPackage) }</div>
            )}
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">1</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{ eventPackage.price }</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{ eventPackage.price }</div>
            { eventPackage.discount && (
              <div className="fs-12 c-blue text-right">- { eventPackage.discount_amount}</div>
            )}
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row className="pv-15 b-border ph-15" gutter={[10,10]} key={index}>
          <Col flex={1}>
            <div className="fs-14 fw-500">{eventPackage.package_name}</div>
            <div className="fs-14 fw-500">1 x { eventPackage.price }</div>
            { eventPackage.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(eventPackage) }</div>
            )}
          </Col>
          <Col flex={0}>
            <div className="fs-14 fw-500 text-right">{ eventPackage.price }</div>
            { eventPackage.discount && (
              <div className="fs-12 c-blue text-right">- { eventPackage.discount_amount}</div>
            )}
          </Col>
        </Row> 
      )
    }
  }

  const renderAddOnRow = (eventAddOn, index) => {
    if (screens.md) {
      return (
        <Row className="pv-15 b-border ph-15" key={index}>
          <Col xs={12}>
            <div className="fs-14 fw-500">{eventAddOn.add_on_name}</div>
            { eventAddOn.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(eventAddOn) }</div>
            )}
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{eventAddOn.quantity}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{ eventAddOn.price }</div>
          </Col>
          { eventAddOn.discount ? (
            <Col xs={4}>
              <div className="fs-14 fw-500 text-right">{ eventAddOn.sub_total }</div>
              <div className="fs-12 c-blue text-right">- { eventAddOn.discount_total}</div>
            </Col>
          ) : (
            <Col xs={4}>
              <div className="fs-14 fw-500 text-right">{ eventAddOn.total }</div>
            </Col>
          )}
        </Row> 
      )
    } else {
      return (
        <Row className="pv-15 b-border ph-15" key={index}>
          <Col flex={1}>
            <div className="fs-14 fw-500">{eventAddOn.add_on_name}</div>
            <div className="fs-14 fw-500">{eventAddOn.quantity} x { eventAddOn.price }</div>
            { eventAddOn.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(eventAddOn) }</div>
            )}
          </Col>
          { eventAddOn.discount ? (
            <Col flex={0}>
              <div className="fs-14 fw-500 text-right">{ eventAddOn.sub_total }</div>
              <div className="fs-12 c-blue text-right">- { eventAddOn.discount_total}</div>
            </Col>
          ) : (
            <Col flex={0}>
              <div className="fs-14 fw-500 text-right">{ eventAddOn.total }</div>
            </Col>
          )}
        </Row> 
      )
    }
  }

  const renderContent = () => {
    const hasTax = getNumericCurrency(summary.tax) != 0
    return (
      <>
        { renderSummaryHeader() }
        { isArray(summary.packages) && summary.packages.map((eventPackage,index) => renderPackageRow(eventPackage, index))}
        { isArray(summary.add_ons) && summary.add_ons.map((eventAddOn,index) => renderAddOnRow(eventAddOn, index))}
        { isArray(summary.packages) && summary.packages.length == 0 && isArray(summary.add_ons) && summary.add_ons.length == 0 && (
          <Row align="middle" className="pv-15 b-border ph-15">
            <Col xs={24}>
              <div className="fs-14 fw-500 f-italic c-text-gray">No packages or add-ons have been assigned to this event</div>
            </Col>
          </Row> 
        )}
        { hasTax && (
          <Row align="middle" className="pv-15 ph-15">
            <Col xs={16} md={20}>
              <div className="fs-14 fw-700 text-right">Subtotal:</div>
            </Col>
            <Col xs={8} md={4}>
              <div className="fs-14 fw-500 text-right">{summary.subtotal}</div>
            </Col>
          </Row> 
        )}
        { hasTax && (
          <Row align="middle" className="pb-15 ph-15">
            <Col xs={16} md={20}>
              <div className="fs-14 fw-700 text-right">{accountSettings.tax_label ?? "Tax"} ({summary.tax_rate}):</div>
            </Col>
            <Col xs={8} md={4}>
              <div className="fs-14 fw-500 text-right">{summary.tax}</div>
            </Col>
          </Row> 
        )}
        <Row align="middle" className="pv-15 t-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Total:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-500 text-right">{summary.total_price}</div>
          </Col>
        </Row> 
        <Row align="middle" className="pb-15 b-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Payments:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-500 text-right">({summary.total_payments})</div>
          </Col>
        </Row> 
        <Row align="middle" className="pv-15 b-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Balance:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-700 text-right">{summary.balance}</div>
          </Col>
        </Row>
      </>
    )
  }

  return renderContent()
};

export default InvoiceSummary;
