import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import qs from "qs";
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FiDownload, FiPrinter } from "react-icons/fi";
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import TimelinePDF from '../../pdfs/timelinePDF';
import { getFormattedTime } from '../../helpers/timelineHelper';
import { formatEventDateLong } from '../../helpers/dateHelper';
import { 
  getSpecialEventSongSummary
} from '../../helpers/specialEventHelper';
import { isArray, isEmpty } from "lodash";
import useApi from '../../hooks/useApi';

import { getAllEventSpecialEvents, getEventTimeline, getTimelineDetails } from '../../api';

const EventTimelinePreviewPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [eventTimeline, setEventTimeline] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());
  const [accountSettings, setAccountSettings] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0)
    const eventId = params.id;
    if (!eventId) {
      navigate("/", { replace: true })
    } else {
      loadPage()
    }
  }, [])

  const loadPage = async () => {
    try {
      const response = await sendRequest(getAllEventSpecialEvents(params.id));
      const sectionResults = isArray(response.special_event_sections) ? response.special_event_sections : []
      setSpecialEventSections(sectionResults)
      setEventName(response.event_name)
      setEventDate(response.event_date)

      const eventTimelineResults = await sendRequest(getEventTimeline(params.id));
      setEventTimeline(eventTimelineResults.content ?? [])
      setAccountSettings(eventTimelineResults.account_settings)

      setLoading(false)

      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (queryStrings.action == "print") {
        window.print()
      }
    } catch {}
  }

  const downloadPDF = async () => {
    try {
      const eventDetails = await getTimelineDetails(params.id)
      const blob = await pdf(( renderPDF(eventDetails.data) )).toBlob();
      saveAs(blob, `timeline-${moment(eventDate).format("YYYY-MM-DD")}.pdf`)
    } catch (error) {
      console.log(error)
    }
  }

  const renderPDF = (data) => {
    return (
      <Document>
        <TimelinePDF event={data}/>
      </Document>
    )
  }

  const renderEventSong = (song, index) => {
    return (
      <div key={index}>
        <div>
          <span className="fs-12 fw-600 line-1-2">"{song.title}"</span>
          <span className="fs-12 fw-400 line-1-2"> - {song.artist}</span>
        </div>
        { !isEmpty(song.notes) && (
          <div className="fs-12 ml-20 c-text-gray">Note: {song.notes}</div>
        )}
      </div>
    )
  }

  const renderSongsOrPlaylist = (item) => {
    const songSummary = getSpecialEventSongSummary(item.event_special_event_id, specialEventSections)

    if (songSummary.found && songSummary.songs.length > 0) {
      if (songSummary.is_playlist) {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              <div className="b-border pb-2 mb-5">
                <span className="fs-12 fw-600 line-1-2">Playlist</span>
                <span className="fs-12 fw-300 line-1-2"> ({songSummary.songs.length} {songSummary.songs.length == 1 ? "song" : "songs"})</span>
              </div>
              <div className="">
                { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
              </div>
            </div>
          )
        } else {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
            </div>
          )
        }
    }
  }

  const renderTimelineItem = (event, index) => {
    return (
      <div className="b-border pv-10" key={index}>
        <div className={`display-flex`}>
          <div className="timeline-preview-row--time">
            <div className="fw-700">{ getFormattedTime(event.time, accountSettings)}</div>
          </div>
          <div className="timeline-preview-row--event">
            <div className="fw-600">{ event.special_event_name }</div>
            <div className="fw-500 c-text-gray line-1-2 mt-5 line-breaks">{ event.notes }</div>
          </div>
        </div>
        { renderSongsOrPlaylist(event) }
      </div>
    )
  }

  const renderSection = (section, index) => {
    const timelineEvents = section.items ?? []

    return (
      <div key={index}>
        <div className="">
          <div className="fs-18 fw-700 mt-30 mb-10 pb-5 pl-5" style={{ borderBottom: "3px solid #e8e8e8"}}>{ section.name }</div>
          <div className="mh-15">
            { timelineEvents.map((x,i) => renderTimelineItem(x, i))}
          </div>
          {timelineEvents.length == 0 && (
            <div className="bg-gray radius-8 p-30 text-center mt-15 c-text-gray">
              <div>No items have been added to this section.</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div>
        <div className="toolbar-container hide-print">
          <div className="toolbar-button" onClick={downloadPDF}>
            <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
          </div>
          <div className="toolbar-button" onClick={() => window.print()}>
            <FiPrinter style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Print
          </div>
        </div>
        <FloatingContainer verticalPadding={30} maxWidth={1000}>
          <div className="print-card">
            <div className="text-center fs-24 fw-700">{ eventName }</div>
            <div className="text-center fs-16 c-text-gray">{formatEventDateLong(eventDate, accountSettings)}</div>
            <div className="text-center fs-16 fw-700 mt-10">Timeline</div>
            { eventTimeline.map((x,i) => renderSection(x,i))}
            { eventTimeline.length == 0 && (
              <div className="bg-gray radius-8 text-center p-50 mt-20 c-text-gray">
                No items have been added to this timeline yet.
              </div>
            )}
          </div>
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="bg-gray" style={{ minHeight: '100vh'}}>
      { renderContent() }
    </div>
  );
}

export default EventTimelinePreviewPage;
