import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pdf, PDFViewer, Document } from '@react-pdf/renderer';
import { Grid } from 'antd';
import { saveAs } from 'file-saver';
import LoadingSpinner from '../../../components/loading';
import TimelinePDF from '../../../pdfs/timelinePDF';
import { getTimelineDetails } from '../../../api';
import { formatEventDateLong } from '../../../helpers/dateHelper';

const TimelinePdfPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [data, setData] = useState({});

  const params = useParams();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    loadPage()
  }, [])

  const loadPage = async () => {
    try {
        setLoading(true)
        const response = await getTimelineDetails(params.id)
        setData(response.data)
        setLoading(false)
    } catch {
      setError(true)
    }
  }

  const downloadPDF = async () => {
    try {
      const blob = await pdf(( renderPDF() )).toBlob();
      saveAs(blob, `timeline-${data.event_date}.pdf`)
    } catch {

    }
  }

  const renderPDF = () => {
    return (
      <Document>
        <TimelinePDF event={data}/>
      </Document>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="mt-80 text-center p-20">
        <div className="fw-700">
          There was an error loading this PDF.
        </div>
        Please try again later.
      </div>
    )
  }

  if (!screens.md) {
    return (
      <div className="mt-80 text-center ph-20 mt-50">
        <div className="fw-700 fs-20">Timeline</div>
        <div className="fw-700 fs-16 mt-20">
          { data.event_name }
        </div>
        <div className="fs-16 c-text-gray">{formatEventDateLong(data.event_date, data.account_settings)}</div>
        <button className="primary-button mt-30" style={{width: 200, borderRadius: 0 }} onClick={downloadPDF}>Download PDF</button>
      </div>
    )
  }

  return (
    <> 
      <div className="pdf-viewer--container">
        <div className="pdf-viewer--header text-right">
          <button className="small-primary-button m-15" style={{ borderRadius: 0 }} onClick={downloadPDF}>Download PDF</button>
        </div>
        <PDFViewer className="pdf-viewer--body" showToolbar={false}>
          {renderPDF()}
        </PDFViewer>
      </div>
    </>
  )
}

export default TimelinePdfPage;
