import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import AdminLayout from '../components/adminLayout';
import ClientLayout from '../components/clientLayout';
import SiteLayout from '../components/siteLayout';
import ProtectedRoute from '../components/protectedRoute';
import AdminProtectedRoute from '../components/adminProtectedRoute';
import Roles from '../constants/roles';
import Permissions from '../constants/permissions';
import EventPermissions from '../constants/eventPermissions';

// Public Pages
import HomePage from "../pages/public/homePage";
import LoginPage from "../pages/public/loginPage";
import SignupPage from "../pages/public/signupPage";
import VerifyEmailPage from "../pages/public/verifyEmailPage";
import ForgotPasswordPage from "../pages/public/forgotPasswordPage";
import ForgotPasswordSentPage from "../pages/public/forgotPasswordSent";
import SetPasswordSentPage from "../pages/public/setPassword";
import ResetPasswordPage from "../pages/public/resetPassword";
import VerificationPage from "../pages/public/verificationPage";
import PublicTimelinePage from "../pages/public/timelinePreviewPage";
import PublicPlaylistPage from "../pages/public/playlistPage";
import InvitationPage from "../pages/public/invitationPage";
import PublicQuestionnairePage from "../pages/public/questionnairePage";
import PublicQuestionnairePDFPage from "../pages/public/questionnairePDFPage";
import PublicSpecialEventsPage from "../pages/public/specialEventsPreviewPage";
import PublicMusicPreviewPage from "../pages/public/muiscPreviewPage";
import PublicEventTimelinePreviewPage from "../pages/public/eventTimelinePreviewPage";
import PublicEventSheetPDFPage from "../pages/public/eventSheetPDFPage";
import ThankYouPage from "../pages/public/thankYouPage";
import TermsAndConditionsPage from "../pages/public/termsAndConditionsPage";
import PrivacyPolicyPage from "../pages/public/privacyPolicyPage";
import PricingPage from "../pages/public/pricingPage";
import ContactPage from "../pages/public/contactPage";
import ContactThankYouPage from "../pages/public/contactThankYouPage";
import SupportPage from "../pages/public/supportPage";
import FeaturesPage from "../pages/public/featuresPage";
import ProposalPage from "../pages/public/proposalPage";
import ContractPage from "../pages/public/contractPage";
import InvoicePage from "../pages/public/invoicePage";
import InvoicePageV2 from "../pages/public/invoicePageV2";
import InvoicePaymentPage from "../pages/public/invoicePaymentPage";
import InvoicePaymentConfirmationPage from "../pages/public/invoicePaymentConfirmationPage";
import ContractPreviewPage from "../pages/public/contractPreviewPage";
import PricingGuidePage from "../pages/public/pricingGuide/pricingGuidePage";
import AccountDeletionPage from "../pages/public/accountDeletionPage";
import ShortLinkPage from "../pages/public/shortLinkPage";

// PDFs
import MusicSelectionsPDFPage from "../pages/public/pdfs/musicSelectionsPdfPage";
import QuestionnairesPDFPage from "../pages/public/pdfs/questionnairesPdfPage";
import TimelinePDFPage from "../pages/public/pdfs/timelinePdfPage";
import EventSheetPdfPage from "../pages/public/pdfs/eventSheetPdfPage";
import AllEventPdfPage from "../pages/public/pdfs/allEventPdfPage";
import InvoicePdfPage from "../pages/public/pdfs/invoicePdfPage";
import InvoiceV2PdfPage from "../pages/public/pdfs/invoiceV2PdfPage";

// System Admin Pages
import SystemDashboardPage from "../pages/system/dashboard";
import SystemRecommendedPlaylistsPage from "../pages/system/systemRecommendedPlaylistsPage";
import NewSystemRecommendedPlaylistPage from "../pages/system/newSystemRecommendedPlaylistPage";
import SystemRecommendedPlaylistGroupsPage from "../pages/system/systemRecommendedPlaylistGroupsPage";
import SystemRecommendedPlaylistGroupListsPage from "../pages/system/systemRecommendedPlaylistGroupListsPage";
import NewSystemRecommendedPlaylistGroupListPage from "../pages/system/newSystemRecommendedPlaylistGroupList";
import NewSystemRecommendedPlaylistGroupPage from "../pages/system/newSystemRecommendedPlaylistGroup";
import SystemEventTypePage from "../pages/system/systemEventTypePage";
import SystemSpecialEventsPage from "../pages/system/systemSpecialEventsPage";
import QuestionnaireTemplatePage from "../pages/system/questionnaireTemplatePage";
import NewQuestionnaireTemplatePage from "../pages/system/newQuestionnaireTemplatePage";
import WorkflowTemplatePage from "../pages/system/workflowTemplatePage";
import NewWorkflowTemplatePage from "../pages/system/newWorkflowTemplatePage";
import LeadsPage from "../pages/system/leadsPage";
import NewLeadPage from "../pages/system/newLeadPage";
import AccountsPage from "../pages/system/accountsPage";
import SystemAccountPage from "../pages/system/accountPage";
import SystemEventDetailsPage from "../pages/system/eventDetailsPage";
import SystemSpecialEventTemplatesPage from "../pages/system/specialEvents/systemSpecialEventTemplatesPage";
import SystemSpecialEventTemplatePage from "../pages/system/specialEvents/systemSpecialEventTemplatePage";
import SystemEmailsPage from "../pages/system/emailsPage";
import SystemRevenuePage from "../pages/system/revenuePage";
import SystemPaymentsPage from "../pages/system/paymentsPage";
import SystemEventInvoicesPage from "../pages/system/eventInvoicesPage";
import SystemAccountInvoicesPage from "../pages/system/accountInvoicesPage";
import SystemBetaUserFeaturesPage from "../pages/system/betaUserFeaturesPage";
import SendgridPage from "../pages/system/sendgridPage";

// Admin Pages
import AdminRestrictedPage from "../pages/admin/restrictedPage";
import AdminHomePage from "../pages/admin/home/adminHomePage";
import EventsPage from "../pages/admin/events/eventsPage";
import ClientsPage from "../pages/admin/clients/clientsPage";
import NewClientPage from "../pages/admin/clients/newClientPage";
import NewEventPage from "../pages/admin/events/newEventPage";
import EventPage from "../pages/admin/events/eventPage";
import EventPrepPage from "../pages/admin/events/eventPrepPage";
import QuestionnairesPage from "../pages/admin/questionnaires/questionnairesPage";
import QuestionnairePage from "../pages/admin/questionnaires/questionnairePage";
import NewQuestionnairePage from "../pages/admin/questionnaires/newQuestionnairePage";
import RecommendedPlaylistsPage from "../pages/admin/recommendedPlaylists/recommendedPlaylistsPage";
import NewRecommendedPlaylistPage from "../pages/admin/recommendedPlaylists/newRecommendedPlaylistPage";
import SpecialEventTemplatesPage from "../pages/admin/eventTemplatesPage";
import AccountPage from "../pages/admin/account/accountPage";
import ChangePlanPage from "../pages/admin/account/changePlanPage";
import PaymentMethodPage from "../pages/admin/paymentMethodPage";
import PaymentMethodCompletePage from "../pages/admin/paymentMethodCompletePage";
import TrialEndedPage from "../pages/admin/trialEndedPage";
import PastDuePage from "../pages/admin/pastDuePage";
import SelectPlanPage from "../pages/admin/selectPlanPage";
import SubscribePage from "../pages/admin/subscribePage";
import SubscribeConfirmationPage from "../pages/admin/subscribeConfirmationPage";
import AccountInactivePage from "../pages/admin/accountInactivePage";
import AddUpdatePaymentMethodPage from "../pages/admin/addEditPaymentMethodPage";
import AddUpdatePaymentMethodCompletePage from "../pages/admin/addEditPaymentMethodCompletePage";
import EmployeesPage from "../pages/admin/employees/employeesPage";
import NewEmployeePage from "../pages/admin/employees/newEmployeePage";
import WorkflowsPage from "../pages/admin/workflows/workflowsPage";
import NewWorkflowPage from "../pages/admin/workflows/newWorkflowPage";
import TasksPage from "../pages/admin/tasks/tasksPage";
import VenuesPage from "../pages/admin/venues/venuesPage";
import NewVenuePage from "../pages/admin/venues/newVenuePage";
import AdminSupportPage from "../pages/admin/supportPage";
import AdminSupportThankYouPage from "../pages/admin/supportThankYouPage";
import AdminMobileAppPage from "../pages/admin/mobileAppPage";
import SpecialEventTemplatePage from "../pages/admin/eventTemplatePage";
import EventSpecialEventTemplatePage from "../pages/admin/events/eventSpecialEventTemplatePage";
import EventSpecialEventsPage from "../pages/admin/events/eventSpecialEventsPage";
import EventSpotifyExportPage from "../pages/admin/events/eventSpotifyExportPage";
import PackagesPage from "../pages/admin/packages/packagesPage";
import EditPackagePage from "../pages/admin/packages/editPackagePage";
import AddOnsPage from "../pages/admin/addOns/addOnsPage";
import EditAddOnPage from "../pages/admin/addOns/editAddOnPage";
import EditContractTemplatePage from "../pages/admin/contracts/editContractTemplatePage";
import ContractTemplatesPage from "../pages/admin/contracts/contractTemplatesPage";
import ContractsPage from "../pages/admin/contracts/contractsPage";
import EventContractPage from "../pages/admin/events/eventContractPage";
import InvoiceTemplatesPage from "../pages/admin/invoiceTemplates/InvoiceTemplatesPage";
import InvoiceTemplatePage from "../pages/admin/invoiceTemplates/invoiceTemplatePage";
import PreviewEventContractPage from "../pages/admin/events/previewEventContractPage";
import SendEventContractPage from "../pages/admin/events/sendEventContractPage";
import SendEventContractConfirmPage from "../pages/admin/events/sendEventContractConfirmPage";
import PricingGuidesPage from "../pages/admin/pricingGuides/pricingGuidesPage";
import EditPricingGuidePage from "../pages/admin/pricingGuides/editPricingGuidePage";
import EventInvoicePage from "../pages/admin/events/invoices/editInvoicePage";
import PreviewInvoicePage from "../pages/admin/events/invoices/previewInvoicePage";
import SendInvoicePage from "../pages/admin/events/invoices/sendInvoicePage";
import SendInvoiceConfirmPage from "../pages/admin/events/invoices/sendInvoiceConfirmPage";
import BookingRequestsPage from "../pages/admin/bookingRequests/bookingRequestsPage";
import BookingRequestPage from "../pages/admin/bookingRequests/bookingRequestPage";
import SettingsPage from "../pages/admin/settings/settingsPage";
import GeneralSettingsPage from "../pages/admin/settings/generalSettingsPage";
import CompanyBrandingPage from "../pages/admin/settings/companyBrandingPage";
import CompanyFinancialsPage from "../pages/admin/settings/companyFinancialsPage";
import CompanyInvoicesPage from "../pages/admin/settings/companyInvoicesPage";
import ClientPortalSettingsPage from "../pages/admin/settings/clientPortalSettingsPage";
import StripeRefreshRedirectPage from "../pages/admin/settings/stripeRefreshRedirectPage";
import MusicIntegrationPage from "../pages/admin/settings/musicIntegrationPage";
import SpotifyLoginCallbackPage from "../pages/admin/settings/spotifyLoginCallbackPage";
import CalendarSettingsPage from "../pages/admin/settings/calendarSettingsPage";
import ExportDataPage from "../pages/admin/settings/exportDataPage";
import EventSettingsPage from "../pages/admin/settings/eventSettingsPage";
import IntegrationsPage from "../pages/admin/settings/integrationsPage";
import EventTemplatesPage from "../pages/admin/eventTemplates/eventTemplates";
import EventTemplatePage from "../pages/admin/eventTemplates/eventTemplate";
import OnboardingPage from "../pages/admin/onboarding/onboardingPage";
import CalendarPage from "../pages/admin/calendar/calendarPage";
import TimeOffPage from "../pages/admin/timeOff/timeOffPage";

// Invoices
import InvoicesPage from "../pages/admin/invoices/invoicesPage";
import EventInvoicePageV2 from "../pages/admin/invoices/invoicePage";
import EditEventInvoicePageV2 from "../pages/admin/invoices/editInvoicePage";
import PreviewInvoicePageV2 from "../pages/admin/invoices/previewInvoicePage";
import SendInvoicePageV2 from "../pages/admin/invoices/sendInvoicePage";
import SendInvoiceConfirmPageV2 from "../pages/admin/invoices/sendInvoiceConfirmPage";

// Reports
import ReportsPage from "../pages/admin/reports/reportsPage";
import ReportsProfitAndLossPage from "../pages/admin/reports/reportsProfitAndLossPage";
import ReportsSpecialEventSongsPage from "../pages/admin/reports/reportsSpecialEventSongsPage";
import EventsByEmployeeReportPage from "../pages/admin/reports/eventsByEmployeeReportPage";
import EventsByDayOfWeekReportPage from "../pages/admin/reports/eventsByDayOfWeekReportPage";
import EventsByMonthReportPage from "../pages/admin/reports/eventsByMonthReportPage";
import EventsByYearReportPage from "../pages/admin/reports/eventsByYearReportPage";
import EventsByEventTypeReportPage from "../pages/admin/reports/eventsByEventTypeReportPage";
import EventsByVenueReportPage from "../pages/admin/reports/eventsByVenueReportPage";
import EventsByPackageReportPage from "../pages/admin/reports/eventsByPackageReportPage";
import EventsByAddOnReportPage from "../pages/admin/reports/eventsByAddOnReportPage";
import IncomeMonthlySummaryReportPage from "../pages/admin/reports/reportsIncomeMonthlySummaryPage";

// Docs
import DocsHomePage from "../pages/admin/docs/docsHomePage";
import DocsSetupPage from "../pages/admin/docs/docsSetupPage";
import DocsQuestionnairesPage from "../pages/admin/docs/docsQuestionnairePage";
import DocsRecommendedPlaylistsPage from "../pages/admin/docs/docsRecommendedPlaylistsPage";
import DocsSpecialEventTemplatesPage from "../pages/admin/docs/docsSpecialEventTemplatesPage";
import DocsWorkflowsPage from "../pages/admin/docs/docsWorkflowsPage";
import DocsContractsPage from "../pages/admin/docs/docsContractsPage";
import DocsInvoicesPage from "../pages/admin/docs/docsInvoicesPage";
import DocsInvitingClientsPage from "../pages/admin/docs/docsInvitingClientsPage";
import DocsWhatClientsSeePage from "../pages/admin/docs/docsWhatClientsSeePage";
import DocsPricingGuidesPage from "../pages/admin/docs/docsPricingGuidesPage";
import DocsBookingRequestsPage from "../pages/admin/docs/docsBookingRequestsPage";
import DocsClientPermissionsPage from "../pages/admin/docs/docsClientPermissionsPage";
import DocsCalendarLinksPage from "../pages/admin/docs/docsCalendarLinksPage";
import DocsInvoicesPaymentsPage from "../pages/admin/docs/invoices/docsInvoicesPaymentsPage";
import DocsInvoiceTemplatesPage from "../pages/admin/docs/invoices/docsInvoiceTemplatesPage";
import DocsPaymentMethodsPage from "../pages/admin/docs/invoices/docsPaymentMethodsPage";
import DocsEventTemplatesPage from "../pages/admin/docs/docsEventTemplatesPage";
import DocsTimeOffPage from "../pages/admin/docs/docsTimeOffPage";

// Client Pages
import ClientHomePage from "../pages/client/homePage";
import ClientMusicPage from "../pages/client/musicPage";
import ClientSpecialEventPlaylistPage from "../pages/client/specialEventPlaylistPage";
import ClientPlaylistPage from "../pages/client/playlistPage";
import ClientQuestionnairesPage from "../pages/client/questionnairesPage";
import ClientQuestionnairePage from "../pages/client/questionnairePage";
import ClientTimelinesPage from "../pages/client/timelinesPage";
import ClientTimelinePage from "../pages/client/timelinePage";
import ClientAccountPage from "../pages/client/accountPage";
import ClientServicesPage from "../pages/client/servicesPage";
import ClientDocumentsPage from "../pages/client/documentsPage";
import ClientEventSelectionPage from "../pages/client/eventSelectionPage";
import ClientMobileAppPage from "../pages/client/clientMobileAppPage";

const PageRoutes = () => {

  const renderRoutes = () => {
    return (
      <Routes>
        <Route exact path="/login" element={<LoginPage/>} />
        <Route element={<SiteLayout/>}>
          <Route exact path="/" element={<HomePage/>} />
          <Route exact path="/signup" element={<SignupPage/>} />
          <Route exact path="/verify-email" element={<VerifyEmailPage/>} />
          <Route exact path="/forgot-password" element={<ForgotPasswordPage/>} />
          <Route exact path="/forgot/password/sent" element={<ForgotPasswordSentPage/>} />
          <Route exact path="/verification" element={<VerificationPage/>} />
          <Route exact path="/reset-password" element={<ResetPasswordPage/>} />
          <Route exact path="/thank-you" element={<ThankYouPage/>} />
          <Route exact path="/terms" element={<TermsAndConditionsPage/>} />
          <Route exact path="/privacy" element={<PrivacyPolicyPage/>} />
          <Route exact path="/pricing" element={<PricingPage/>} />
          <Route exact path="/contact" element={<ContactPage/>} />
          <Route exact path="/contact/thank-you" element={<ContactThankYouPage/>} />
          <Route exact path="/support" element={<SupportPage/>} />
          <Route exact path="/features" element={<FeaturesPage/>} />
          <Route exact path="/account-deletion" element={<AccountDeletionPage/>} />
        </Route>

        <Route exact path="/r/:code" element={<ShortLinkPage/>} />
        <Route exact path="/invitation" element={<InvitationPage/>} />
        <Route exact path="/create/password" element={<SetPasswordSentPage/>} />
        <Route exact path="/timeline/:id" element={<PublicTimelinePage/>} />
        <Route exact path="/playlists/:id" element={<PublicPlaylistPage/>} />
        <Route exact path="/questionnaire/:id" element={<PublicQuestionnairePage/>} />
        <Route exact path="/questionnaire/:id/pdf" element={<PublicQuestionnairePDFPage/>} />
        <Route exact path="/event/:id/special-events" element={<PublicSpecialEventsPage/>} />
        <Route exact path="/event/:id/playlists" element={<PublicPlaylistPage/>} />
        <Route exact path="/event/:id/music" element={<PublicMusicPreviewPage/>} />
        <Route exact path="/event/:id/timeline" element={<PublicEventTimelinePreviewPage/>} />
        <Route exact path="/event/:id/pdf" element={<PublicEventSheetPDFPage/>} />
        {/* <Route exact path="/proposal/:id" element={<ProposalPage/>} /> */}
        <Route exact path="/contract/sign/:id" element={<ContractPage/>} />
        <Route exact path="/contract/preview/:id" element={<ContractPreviewPage/>} />
        <Route exact path="/pricing-guide/:id" element={<PricingGuidePage/>} />
        <Route exact path="/v/invoice/:id" element={<InvoicePageV2/>} /> 
        <Route exact path="/v/invoice/:id/payment" element={<InvoicePaymentPage/>} />
        <Route exact path="/v/invoice/:id/confirmation" element={<InvoicePaymentConfirmationPage/>} />
        <Route exact path="/invoice/:id" element={<InvoicePage/>} />

        <Route exact path="/pdfs/event/:id/music" element={<MusicSelectionsPDFPage/>} />
        <Route exact path="/pdfs/event/:id/questionnaires" element={<QuestionnairesPDFPage/>} />
        <Route exact path="/pdfs/event/:id/timeline" element={<TimelinePDFPage/>} />
        <Route exact path="/pdfs/event/:id/details" element={<EventSheetPdfPage/>} />
        <Route exact path="/pdfs/event/:id/all" element={<AllEventPdfPage/>} />
        <Route exact path="/pdfs/invoice/:id" element={<InvoicePdfPage/>} />
        <Route exact path="/pdfs/v2/invoice/:id" element={<InvoiceV2PdfPage/>} />

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/system/dashboard" element={<SystemDashboardPage/>}/>
            <Route exact path="/system/recommended-playlist-groups" element={<SystemRecommendedPlaylistGroupsPage/>}/>
            <Route exact path="/system/recommended-playlist-groups/new" element={<NewSystemRecommendedPlaylistGroupPage/>}/>
            <Route exact path="/system/recommended-playlist-groups/:id/edit" element={<NewSystemRecommendedPlaylistGroupPage/>}/>
            <Route exact path="/system/recommended-playlists" element={<SystemRecommendedPlaylistsPage/>}/>
            <Route exact path="/system/recommended-playlists/new" element={<NewSystemRecommendedPlaylistPage/>}/>
            <Route exact path="/system/recommended-playlists/:id/edit" element={<NewSystemRecommendedPlaylistPage/>}/>
            <Route exact path="/system/recommended-playlist-group-lists" element={<SystemRecommendedPlaylistGroupListsPage/>}/>
            <Route exact path="/system/recommended-playlist-group-lists/new" element={<NewSystemRecommendedPlaylistGroupListPage/>}/>
            <Route exact path="/system/recommended-playlist-group-lists/:id/edit" element={<NewSystemRecommendedPlaylistGroupListPage/>}/>
            <Route exact path="/system/special-events" element={<SystemSpecialEventsPage/>}/>
            <Route exact path="/system/event-types" element={<SystemEventTypePage/>}/>
            <Route exact path="/system/questionnaire-templates" element={<QuestionnaireTemplatePage/>}/>
            <Route exact path="/system/questionnaire-templates/new" element={<NewQuestionnaireTemplatePage/>}/>
            <Route exact path="/system/questionnaire-templates/:id/edit" element={<NewQuestionnaireTemplatePage/>}/>
            <Route exact path="/system/workflows" element={<WorkflowTemplatePage/>}/>
            <Route exact path="/system/workflows/new" element={<NewWorkflowTemplatePage/>}/>
            <Route exact path="/system/workflows/:id/edit" element={<NewWorkflowTemplatePage/>}/>
            <Route exact path="/system/leads" element={<LeadsPage/>}/>
            <Route exact path="/system/leads/new" element={<NewLeadPage/>}/>
            <Route exact path="/system/leads/:id/edit" element={<NewLeadPage/>}/>
            <Route exact path="/system/accounts" element={<AccountsPage/>}/>
            <Route exact path="/system/accounts/:id" element={<SystemAccountPage/>}/>
            <Route exact path="/system/accounts/:account_id/events/:event_id" element={<SystemEventDetailsPage/>}/>
            <Route exact path="/system/special-event-templates" element={<SystemSpecialEventTemplatesPage/>}/>
            <Route exact path="/system/special-event-templates/:id" element={<SystemSpecialEventTemplatePage/>}/>
            <Route exact path="/system/emails" element={<SystemEmailsPage/>}/>
            <Route exact path="/system/revenue" element={<SystemRevenuePage/>}/>
            <Route exact path="/system/payments" element={<SystemPaymentsPage/>}/>
            <Route exact path="/system/event-invoices" element={<SystemEventInvoicesPage/>}/>
            <Route exact path="/system/event-invoices/:account_id" element={<SystemAccountInvoicesPage/>}/>
            <Route exact path="/system/beta-features" element={<SystemBetaUserFeaturesPage/>}/>
            <Route exact path="/system/sendgrid" element={<SendgridPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.OWNER]}/>}>
          <Route exact path="/admin/setup/stripe-refresh" element={<StripeRefreshRedirectPage/>}/>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/account/payment-method" element={<PaymentMethodPage/>}/>
            <Route exact path="/admin/account/payment-method/complete" element={<PaymentMethodCompletePage/>}/>
            <Route exact path="/admin/account/change-plan" element={<ChangePlanPage/>}/>

            <Route exact path="/admin/setup/general" element={<GeneralSettingsPage/>}/>
            <Route exact path="/admin/setup/branding" element={<CompanyBrandingPage/>}/>
            <Route exact path="/admin/setup/financials" element={<CompanyFinancialsPage/>}/>
            <Route exact path="/admin/setup/payments" element={<CompanyInvoicesPage/>}/>
            <Route exact path="/admin/setup/client-portal" element={<ClientPortalSettingsPage/>}/>
            <Route exact path="/admin/setup/music-integration" element={<MusicIntegrationPage/>}/>
            <Route exact path="/admin/setup/calendar" element={<CalendarSettingsPage/>}/>
            <Route exact path="/admin/setup/export" element={<ExportDataPage/>}/>
            <Route exact path="/admin/setup/event-settings" element={<EventSettingsPage/>}/>
            <Route exact path="/admin/setup/integrations" element={<IntegrationsPage/>}/>

            <Route exact path="/admin/onboarding" element={<OnboardingPage/>}/>

            <Route exact path="/admin/reports" element={<ReportsPage/>} />
            <Route exact path="/admin/reports/profit-and-loss" element={<ReportsProfitAndLossPage/>} />
            <Route exact path="/admin/reports/special-event-songs" element={<ReportsSpecialEventSongsPage/>} />
            <Route exact path="/admin/reports/events-by-staff" element={<EventsByEmployeeReportPage/>} />
            <Route exact path="/admin/reports/events-by-day-of-week" element={<EventsByDayOfWeekReportPage/>} />
            <Route exact path="/admin/reports/events-by-month" element={<EventsByMonthReportPage/>} />
            <Route exact path="/admin/reports/events-by-year" element={<EventsByYearReportPage/>} />
            <Route exact path="/admin/reports/events-by-event-type" element={<EventsByEventTypeReportPage/>} />
            <Route exact path="/admin/reports/events-by-venue" element={<EventsByVenueReportPage/>} />
            <Route exact path="/admin/reports/events-by-package" element={<EventsByPackageReportPage/>} />
            <Route exact path="/admin/reports/events-by-add-on" element={<EventsByAddOnReportPage/>} />
            <Route exact path="/admin/reports/income/monthly-summary" element={<IncomeMonthlySummaryReportPage/>} />
          </Route>
          <Route element={<SiteLayout/>}>
            <Route exact path="/admin/select-plan" element={<SelectPlanPage/>}/>
            <Route exact path="/admin/subscribe" element={<SubscribePage/>}/>
            <Route exact path="/admin/subscribe/confirmation" element={<SubscribeConfirmationPage/>}/>
            <Route exact path="/admin/payment-method" element={<AddUpdatePaymentMethodPage/>}/>
          </Route>
          <Route exact path="/admin/setup/spotify/login" element={<SpotifyLoginCallbackPage/>}/>
        </Route>

        <Route element={<SiteLayout/>}>
          <Route exact path="/admin/payment-method/complete" element={<AddUpdatePaymentMethodCompletePage/>}/>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.CREATE_EVENTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/events/new" element={<NewEventPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_CLIENTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/clients" element={<ClientsPage/>}/>
            <Route exact path="/admin/clients/new" element={<NewClientPage/>}/>
            <Route exact path="/admin/clients/:id/edit" element={<NewClientPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_EMPLOYEES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/staff" element={<EmployeesPage/>}/>
            <Route exact path="/admin/staff/new" element={<NewEmployeePage/>}/>
            <Route exact path="/admin/staff/:id/edit" element={<NewEmployeePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_VENUES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/venues" element={<VenuesPage/>}/>
            <Route exact path="/admin/venues/new" element={<NewVenuePage/>}/>
            <Route exact path="/admin/venues/:id/edit" element={<NewVenuePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_QUESTIONNAIRES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/questionnaires" element={<QuestionnairesPage/>}/>
            <Route exact path="/admin/questionnaires/new" element={<NewQuestionnairePage/>}/>
            <Route exact path="/admin/questionnaires/:id" element={<QuestionnairePage/>}/>
            <Route exact path="/admin/questionnaires/:id/edit" element={<NewQuestionnairePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_REC_PLAYLISTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/recommended-playlists" element={<RecommendedPlaylistsPage/>}/>
            <Route exact path="/admin/recommended-playlists/new" element={<NewRecommendedPlaylistPage/>}/>
            <Route exact path="/admin/recommended-playlists/:id/edit" element={<NewRecommendedPlaylistPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_SPECIAL_EVENTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/special-event-templates" element={<SpecialEventTemplatesPage/>}/>
            <Route exact path="/admin/special-event-templates/:id" element={<SpecialEventTemplatePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_EVENT_TEMPLATES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/event-templates" element={<EventTemplatesPage/>}/>
            <Route exact path="/admin/event-templates/:id" element={<EventTemplatePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_CONTRACT_TEMPLATES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/contract-templates" element={<ContractTemplatesPage/>}/>
          </Route>
          <Route exact path="/admin/contract-templates/:id/edit" element={<EditContractTemplatePage/>}/>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_INVOICE_TEMPLATES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/invoice-templates" element={<InvoiceTemplatesPage/>}/>
            <Route exact path="/admin/invoice-templates/:id" element={<InvoiceTemplatePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_PACKAGES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/packages" element={<PackagesPage/>}/>
            <Route exact path="/admin/packages/:id/edit" element={<EditPackagePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_ADD_ONS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/addons" element={<AddOnsPage/>}/>
            <Route exact path="/admin/addons/:id/edit" element={<EditAddOnPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_WORKFLOWS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/workflows" element={<WorkflowsPage/>}/>
            <Route exact path="/admin/workflows/new" element={<NewWorkflowPage/>}/>
            <Route exact path="/admin/workflows/:id/edit" element={<NewWorkflowPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_PRICING_GUIDES]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/pricing-guides" element={<PricingGuidesPage/>}/>
            <Route exact path="/admin/pricing-guides/:id/edit" element={<EditPricingGuidePage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.VIEW_BOOKING_REQUESTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/booking-requests" element={<BookingRequestsPage/>}/>
            <Route exact path="/admin/booking-requests/:id" element={<BookingRequestPage/>}/>
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_INVOICES]}/>}>
          <Route exact path="/admin/invoices/:id/edit" element={<EditEventInvoicePageV2/>} />
          <Route exact path="/admin/invoices/:id/preview" element={<PreviewInvoicePageV2/>} />
          <Route exact path="/admin/invoices/:id/send" element={<SendInvoicePageV2/>} />
          <Route exact path="/admin/invoices/:id/confirmation" element={<SendInvoiceConfirmPageV2/>} />
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/invoices" element={<InvoicesPage/>} />
            <Route exact path="/admin/invoices/:id" element={<EventInvoicePageV2/>} />
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]} permissions={[Permissions.MANAGE_CONTRACTS]}/>}>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/contracts" element={<ContractsPage/>} />
          </Route>
        </Route>

        <Route element={<AdminProtectedRoute roles={[Roles.SYSTEM_ADMIN, Roles.OWNER, Roles.ADMIN]}/>}>
          <Route element={<SiteLayout/>}>
            <Route exact path="/admin/trial-ended" element={<TrialEndedPage/>}/>
            <Route exact path="/admin/past-due" element={<PastDuePage/>}/>
            <Route exact path="/admin/inactive" element={<AccountInactivePage/>}/>
          </Route>
          <Route element={<AdminLayout/>}>
            <Route exact path="/admin/restricted" element={<AdminRestrictedPage/>}/>
            <Route exact path="/admin/dashboard" element={<AdminHomePage/>}/>
            <Route exact path="/admin/events" element={<EventsPage/>}/>
            <Route exact path="/admin/events/:id" element={<EventPage/>}/>
            <Route exact path="/admin/events/:id/edit" element={<NewEventPage/>}/>
            <Route exact path="/admin/events/:id/prep" element={<EventPrepPage/>}/>
            <Route exact path="/admin/events/:event_id/timeline" element={<ClientTimelinePage/>}/>
            <Route exact path="/admin/events/:event_id/questionnaires/:id" element={<ClientQuestionnairePage/>}/>
            <Route exact path="/admin/events/:event_id/playlists/:id" element={<ClientPlaylistPage/>} />
            <Route exact path="/admin/events/:event_id/special-events" element={<EventSpecialEventsPage/>} />
            <Route exact path="/admin/events/:event_id/special-events/:id" element={<ClientSpecialEventPlaylistPage/>} />
            <Route exact path="/admin/events/:event_id/event-template" element={<EventSpecialEventTemplatePage/>} />
            <Route exact path="/admin/events/:event_id/spotify-export" element={<EventSpotifyExportPage/>} />

            <Route exact path="/admin/tasks" element={<TasksPage/>}/>

            <Route exact path="/admin/calendar" element={<CalendarPage/>}/>

            <Route exact path="/admin/account" element={<AccountPage/>}/>
            <Route exact path="/admin/support" element={<AdminSupportPage/>}/>
            <Route exact path="/admin/support/thank-you" element={<AdminSupportThankYouPage/>}/>

            <Route exact path="/admin/setup" element={<SettingsPage/>} />

            <Route exact path="/admin/time-off" element={<TimeOffPage/>} />

            <Route exact path="/admin/mobile-app" element={<AdminMobileAppPage/>}/>

            <Route exact path="/admin/docs" element={<DocsHomePage/>}/>
            <Route exact path="/admin/docs/setup" element={<DocsSetupPage/>}/>
            <Route exact path="/admin/docs/questionnaires" element={<DocsQuestionnairesPage/>}/>
            <Route exact path="/admin/docs/workflows" element={<DocsWorkflowsPage/>}/>
            <Route exact path="/admin/docs/contracts" element={<DocsContractsPage/>}/>
            <Route exact path="/admin/docs/invoices" element={<DocsInvoicesPage/>}/>
            <Route exact path="/admin/docs/invoices-payments" element={<DocsInvoicesPaymentsPage/>}/>
            <Route exact path="/admin/docs/invoice-templates" element={<DocsInvoiceTemplatesPage/>}/>
            <Route exact path="/admin/docs/event-templates" element={<DocsEventTemplatesPage/>}/>
            <Route exact path="/admin/docs/payment-methods" element={<DocsPaymentMethodsPage/>}/>
            <Route exact path="/admin/docs/special-event-templates" element={<DocsSpecialEventTemplatesPage/>}/>
            <Route exact path="/admin/docs/recommended-playlists" element={<DocsRecommendedPlaylistsPage/>}/>
            <Route exact path="/admin/docs/inviting-clients" element={<DocsInvitingClientsPage/>}/>
            <Route exact path="/admin/docs/what-clients-see" element={<DocsWhatClientsSeePage/>}/>
            <Route exact path="/admin/docs/pricing-guides" element={<DocsPricingGuidesPage/>}/>
            <Route exact path="/admin/docs/booking-requests" element={<DocsBookingRequestsPage/>}/>
            <Route exact path="/admin/docs/client-permissions" element={<DocsClientPermissionsPage/>}/>
            <Route exact path="/admin/docs/calendar-links" element={<DocsCalendarLinksPage/>}/>
            <Route exact path="/admin/docs/time-off" element={<DocsTimeOffPage/>}/>
          </Route>
          <Route exact path="/admin/questionnaires/:id/preview" element={<QuestionnairePage/>}/>

          <Route exact path="/admin/events/:event_id/contracts/:id/edit" element={<EventContractPage/>} />
          <Route exact path="/admin/events/:event_id/contracts/:id/preview" element={<PreviewEventContractPage/>} />
          <Route exact path="/admin/events/:event_id/contracts/:id/send" element={<SendEventContractPage/>} />
          <Route exact path="/admin/events/:event_id/contracts/:id/confirmation" element={<SendEventContractConfirmPage/>} />

          <Route exact path="/admin/events/:event_id/invoices/:id/edit" element={<EventInvoicePage/>} />
          <Route exact path="/admin/events/:event_id/invoices/:id/preview" element={<PreviewInvoicePage/>} />
          <Route exact path="/admin/events/:event_id/invoices/:id/send" element={<SendInvoicePage/>} />
          <Route exact path="/admin/events/:event_id/invoices/:id/confirmation" element={<SendInvoiceConfirmPage/>} />
        </Route>

        <Route element={<ProtectedRoute/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/home" element={<ClientHomePage/>}/>
            <Route exact path="/client/account" element={<ClientAccountPage/>}/>
            <Route exact path="/client/mobile-app" element={<ClientMobileAppPage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.VIEW_MUSIC]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/music" element={<ClientMusicPage/>}/>
            <Route exact path="/client/events/:event_id/playlists/:id" element={<ClientPlaylistPage/>} />
            <Route exact path="/client/events/:event_id/special-events/:id" element={<ClientSpecialEventPlaylistPage/>} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.VIEW_QUESTIONNAIRES]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/questionnaires" element={<ClientQuestionnairesPage/>}/>
            <Route exact path="/client/events/:event_id/questionnaires/:id" element={<ClientQuestionnairePage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.VIEW_TIMELINE]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/timeline" element={<ClientTimelinesPage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.EDIT_TIMELINE]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/events/:event_id/timeline" element={<ClientTimelinePage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.VIEW_FINANCIALS, EventPermissions.VIEW_SERVICES]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/services" element={<ClientServicesPage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute permissions={[EventPermissions.VIEW_DOCUMENTS]}/>}>
          <Route element={<ClientLayout/>}>
            <Route exact path="/client/documents" element={<ClientDocumentsPage/>}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute/>}>
          <Route exact path="/client/event-selection" element={<ClientEventSelectionPage/>}/>
        </Route>

        <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
    )
  }

  return renderRoutes();
}

export default PageRoutes;
